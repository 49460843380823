import { faChevronLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import * as React from "react";
import UserContext from "../../../../contexts/User";
import { setFeedbackMassage } from "../../../../functions/feedbackFunctions";
import { CloseMenu } from "../../../../functions/menuFunctions";
import axiosInstance from "../../../../services/axiosInstance";
import Button from "../../../UI/Buttons/Button";
import IconButton from "../../../UI/Buttons/IconButton";
import colors from "../../../UI/constants/colors";
import Feedback from "../../../UI/Feedback/Feedback";
import Input from "../../../UI/Inputs/Input/Input";
import { Form, MenuActions, MenuHeader } from "../../styles";

const LoginPage = (props: { visible?: boolean, changePage: (page: string) => void; }) => {
	const [disableButton, setDisableButton] = React.useState(false);
	const Data = useContext(UserContext);
	const [loading, setLoading] = React.useState(false);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDisableButton(true);
		setLoading(true);
		const fd = new FormData(event.currentTarget);
		if (fd.get("password") === fd.get("re-password")) {
			return setFeedbackMassage({ message: "Senhas não conferem", success: false, id: "loginFeedback" });
		}
		axiosInstance
			.post("/api/auth/login", fd)
			.catch((error) => { return setFeedbackMassage({ message: error.response.status, success: false, id: "loginFeedback" }); })
			.then((res) => {
				if (res && !res.data.error) {
					localStorage.setItem("gghub@user", res.data.access_token);
					getUser();
				}
				else {
					setDisableButton(false);
					setLoading(false);
				}
			});

	};

	const getUser = () => {
		axiosInstance
			.get("/api/auth/user", { headers: { Authorization: `Bearer ${localStorage.getItem("gghub@user")}` } })
			.then((res) => {
				if (res.status === 200) {
					Data?.setUser(res.data);
					props.changePage("OptionsPage");
					window.location.reload();
				}
			})
			.catch((error) => {
				return setFeedbackMassage({ message: error.response.status, success: false, id: "loginFeedback" });
			}).finally(() => {
				setDisableButton(false);
				setLoading(false);
			});
	};

	if (!props.visible) {
		return <></>;
	}

	return (
		<>
			<MenuHeader className="two-icons">
				<IconButton onClick={() => props.changePage("UserPage")} icon={faChevronLeft} className="back-button" color={colors.PRIMARY_GREEN} nobackground />
				<IconButton className="close-menu" onClick={() => {
					CloseMenu();
				}} icon={faTimes} color={colors.PRIMARY_GREEN} nobackground />
			</MenuHeader>
			<MenuActions>
				<Form onSubmit={handleSubmit}>
					<Input name="email">
						Email
					</Input>
					<div style={{display: "flex", flexDirection: "column", gap: 5}}>
						<Input name="password" type="password">
							Senha
						</Input>
						<span style={{fontSize: 18, textDecoration: "underline", color: colors.PRIMARY_GREEN, cursor: "pointer"}} onClick={() => props.changePage("RequestPage")}>Recuperar senha</span>
					</div>
					<Feedback id="loginFeedback" />
					<Button loading={loading} type="submit" disabled={disableButton}>
						ENTRAR
					</Button>
				</Form>
			</MenuActions>
		</>
	);
};

export default LoginPage;