import { faFistRaised } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import Template from "../../components/Template";
import Carousel from "../../components/UI/Carousel/Carousel";
import axiosInstance from "../../services/axiosInstance";
import { Challenges } from "../../types/Challenges";
import Challenge from "../../components/UI/Challange/Challenge";
import { MenuTitle } from "../Home/styles";
import seo from "../../helpers/seo";
import { SwiperSlide } from "swiper/react";

const MyChallenges = () => {
	const [challenges, setChallenges] = useState<Challenges[]>([]);

	useEffect(() => {
		seo({
			title: "Meus Torneios"
		});

		axiosInstance.get("/api/matches/solo/my").then(res => {
			setChallenges(res.data.matches);
		});
	}, []);

	return <Template secondaryHeader icon={faFistRaised} text="Meus Desafios">
		<MenuTitle>PROCURANDO</MenuTitle>
		<Carousel height={350} >
			<>{challenges && challenges.map((challenge) => {
				if (challenge.status === "searching") {
					return <SwiperSlide style={{ height: 295, width: 450 }} key={challenge.id}>
						<Challenge data={challenge} />
					</SwiperSlide>;
				}
			})}</>
		</Carousel>
		<MenuTitle>EM ANDAMENTO</MenuTitle>
		<Carousel height={350} >
			<>{challenges && challenges.map((challenge) => {
				if (challenge.status === "validating" || challenge.status === "ingame") {
					return <SwiperSlide style={{ height: 295, width: 450 }} key={challenge.id}>
						<Challenge data={challenge} />
					</SwiperSlide>;
				}
			})}</>
		</Carousel>
		<MenuTitle>ENCERRADOS OU REPORTADOS</MenuTitle>
		<Carousel height={350} >
			<>{challenges && challenges.map((challenge) => {
				if (challenge.status === "ended" || challenge.status === "reported") {
					return <SwiperSlide style={{ height: 295, width: 450 }} key={challenge.id}>
						<Challenge data={challenge} />
					</SwiperSlide>;
				}
			})}</>
		</Carousel>
	</Template>;
};

export default MyChallenges;