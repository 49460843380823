import styled from "styled-components";
import colors from "../../components/UI/constants/colors";

type GameDivProps = {
    backgroundImage: string;
}

export const MenuBackground = styled.div`
    background-image: url(${(props: GameDivProps) => props.backgroundImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 500px;
    box-shadow: 0px 0px 10px 2px ${colors.PRIMARY_GREEN};

    @media (max-width: 768px) {
        display: none;
    }
`;

export const AddChallengeContainer = styled.div`
    align-items: end;
    display: flex;
    margin-bottom: 30px;
    margin-right: 18px;

    @media (max-width: 768px) {
        align-items: center;
        margin-bottom: 0;
    }
`;

export const MenuTitle = styled.span`
    margin-left: 51px;
    margin-top: 60px;
    font-size: 27px;
    color: ${colors.WHITE};
    font-family: "Inter-Bold", sans-serif;
    margin-bottom: 30px;
    display: block;

    @media (max-width: 768px) {
        width: fit-content;
        margin: 30px auto;
    }
`;

export const GameDiv = styled.div<GameDivProps>`
    height: 400px;
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &::before {
      content: "";
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.2;
      background-image: url(${props => props.backgroundImage});
    }
`;

export const FutureGameSpan = styled.span`
    align-self: end;
    font-size: 30px;
    font-family: "Inter-Bold", sans-serif;
    color: ${colors.WHITE};
    margin-bottom: 35px;
`;

export const GameLogo = styled.img`
    width: ${props => props.width || "80%"};
    position: absolute;
`;

export const CreateChallengeButton = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 18px;
    justify-content: center;
    flex-direction: column;
    border: 1px solid ${colors.PRIMARY_GREEN};
    border-radius: 10px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:active {
        transform: scale(.95);
    }

    & span {
        font-size: 27px;
        color: ${colors.PRIMARY_GREEN};
    }
`;