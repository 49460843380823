import { faChevronLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import * as React from "react";
import { setFeedbackMassage } from "../../../../functions/feedbackFunctions";
import { CloseMenu } from "../../../../functions/menuFunctions";
import axiosInstance from "../../../../services/axiosInstance";
import Button from "../../../UI/Buttons/Button";
import CheckBoxButton from "../../../UI/Buttons/CheckBoxButton";
import IconButton from "../../../UI/Buttons/IconButton";
import colors from "../../../UI/constants/colors";
import Feedback from "../../../UI/Feedback/Feedback";
import Input from "../../../UI/Inputs/Input/Input";
import { Form, MenuActions, MenuHeader } from "../../styles";

const SigninPage = (props: { visible?: boolean, changePage: (page: string) => void; }) => {
	const [checkedButton, setCheckedButton] = React.useState(false);
	const [disableButton, setDisableButton] = React.useState(!checkedButton);
	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		setDisableButton(!checkedButton);
	}, [checkedButton]);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		setDisableButton(true);
		setLoading(true);
		event.preventDefault();
		const fd = new FormData(event.currentTarget);
		if (fd.get("password") !== fd.get("re-password")) {
			setFeedbackMassage({ message: "Senhas não conferem", success: false, id: "signInFeedback" });
			setDisableButton(false);
			return setLoading(false);
		}
		axiosInstance.post("/api/users/register", fd)
			.then((res) => { if(res.data.error){setFeedbackMassage({ message: res.data.error, success: false, id: "signInFeedback" }); } else {
				props.changePage("ConfirmPage");
			}})
			.catch((error) => {
				setFeedbackMassage({ message: error.response.status, success: false, id: "signInFeedback" });
			})
			.finally(() => { setDisableButton(false); setLoading(false); });
	};

	if (!props.visible) {
		return <></>;
	}

	return (
		<>
			<MenuHeader className="two-icons">
				<IconButton onClick={() => props.changePage("UserPage")} icon={faChevronLeft} className="back-button" color={colors.PRIMARY_GREEN} nobackground />
				<IconButton className="close-menu" onClick={() => {
					CloseMenu();
				}} icon={faTimes} color={colors.PRIMARY_GREEN} nobackground />
			</MenuHeader>
			<MenuActions>
				<Form onSubmit={handleSubmit}>
					<Input name="nickname" type="text">
						Nome do usuário
					</Input>
					<Input name="email" type="email">
						Email
					</Input>
					<Input name="password" type="password">
						Senha
					</Input>
					<Input name="re-password" type="password">
						Repita a senha
					</Input>
					<Input style={{width: 350, maxWidth: "100%"}} name="birthdate" type="date">
						Data de nascimento
					</Input>
					<CheckBoxButton
						onChange={() => {
							setCheckedButton(!checkedButton);
						}}
						checked={checkedButton} id="politcs">
						<span>Aceito os <a href="/termos-de-uso-e-privacidade" target="blank">termos de uso</a> e a <a href="/termos-de-uso-e-privacidade" target="blank">política de privacidade</a></span>
					</CheckBoxButton>
					<Feedback id="signInFeedback"></Feedback>
					<Button type="submit" disabled={disableButton} loading={loading}>
						CADASTRAR
					</Button>
				</Form>
			</MenuActions>
		</>
	);
};

export default SigninPage;