import styled from "styled-components";
import colors from "../../../../components/UI/constants/colors";

export const Games = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 18px;
`;

export const GameButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    border: 1px solid ${colors.PRIMARY_GREEN};
    transition: transform 0.2s ease-in-out, background-color 0.4s ease-in-out;

    &:hover {
        background-color: ${colors.PRIMARY_GREEN};
        color: ${colors.WHITE};
    }

    &:active {
        transform: scale(0.9);
    }

`;