import styled from "styled-components";
import colors from "../../constants/colors";

export const StyledInput = styled.input`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 17px 15px 0px 9px;
    max-width: 315px;
    height: 60px;
    border: 1px solid ${colors.PRIMARY_GREEN};
    border-radius: 10px;
    font-size: 18px;
    color: ${colors.WHITE};
    background-color: ${colors.PRIMARY_BLACK};

    &::-webkit-file-upload-button {
        background: transparent;
        color: ${colors.PRIMARY_GREEN};
        border: none;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px #1D2229 inset !important;
        box-shadow: 0 0 0 30px #1D2229 inset !important;
        -webkit-text-fill-color: white !important;
        transition: background-color 5000s ease-in-out 0s;
    }

    &::-webkit-datetime-edit { color: transparent; }

    &::-webkit-calendar-picker-indicator {
            align-self: flex-start;
            margin-top: 2px;
            background-color: ${colors.PRIMARY_GREEN}; opacity: 1;
            border-radius: 5px;
        }

    &::placeholder {
        opacity: 0;
    }

    &::-webkit-input-placeholder { /* Edge */
        opacity: 0;

    }

    &:-ms-input-placeholder { /* Internet Explorer */
        opacity: 0;
    }

    &:focus {
        border: 1px solid ${colors.PRIMARY_GREEN};
        outline: none;
        background-color: ${colors.PRIMARY_BLACK};

        &::-webkit-datetime-edit { color: #FFF; }

        

        &::placeholder {
            opacity: 1;
        }

        &::-webkit-input-placeholder { /* Edge */
            opacity: 1;

        }

        &:-ms-input-placeholder { /* Internet Explorer */
            opacity: 1;
        }
    }

    &.disabled {
        background-color: ${colors.SECONDARY_GRAY};
        cursor: not-allowed;
        border: none;
    }
    
`;

export const InputDiv = styled.div`
    position: relative;
    max-width: 100%;
    width: ${(props: { width?: string }) => props.width};
    
    & input.file ~ label {
        color: transparent !important;
    }

    & input {
        &::-webkit-datetime-edit { 
            color: #FFF; 
        }
        &::-webkit-calendar-picker-indicator { 
            color: #FFF; 
        }
    }

    & input:focus {
        &::-webkit-datetime-edit { 
            color: #FFF !important; 
        }
        &::-webkit-calendar-picker-indicator { 
            color: #FFF !important; 
        }
    }

    & label {
        position: absolute;
        font-size: 18px;
        top: 5px;
        left: 10px;
        
        color: ${colors.PRIMARY_GREEN};
        transition: all 0.2s ease-in-out;
    }

    & input.no-input ~ label {
        top: 18px;
        left: 10px;
        font-size: 22px;

        color: ${colors.PRIMARY_GREEN};
    }

    & input:focus ~ label {
        font-size: 18px !important;
        top: 5px !important;
        left: 10px !important;
    }

    & input.no-input {
        border: 1px solid ${colors.PRIMARY_GREEN};
        &::-webkit-datetime-edit { 
            color: transparent; 
        }
        &::-webkit-calendar-picker-indicator {
            color: transparent;
        }
    }
`;