import styled from "styled-components";
import colors from "../../../components/UI/constants/colors";

export const Container = styled.div`
    display: flex;
    max-width: 100%;
    align-items: center;
    padding: 10px;
    border-radius: 5px;

    & img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
    }

    & span {
        font-size: 32px;
        color: ${colors.WHITE};
        font-family: "Inter-Bold", sans-serif;
    }
`;

export const FlexibleSpace = styled.div`
    height: 49px;
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: center;

    @media (max-width: 760px) {
        height: 0px;
    }
`;

export const UsersDiv = styled.div`
    display: flex;
    flex-direction: row;

    @media (min-width: 760px){
        flex-direction: column;
    }
`;
