import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { SwiperSlide } from "swiper/react";
import Template from "../../components/Template";
import Carousel from "../../components/UI/Carousel/Carousel";
import TournamentEntrance from "../../components/UI/TournamentEntrance/TournamentEntrance";
import axiosInstance from "../../services/axiosInstance";
import { WarzoneTournaments } from "../../types/WarzoneTournaments";
import { MenuTitle } from "./styles";

const Tournaments = () => {
	const [tounaments, setTounaments] = useState<WarzoneTournaments[]>();

	useEffect(() => {
		axiosInstance.get("api/tournament/warzone/index").then((res) => {
			setTounaments(res.data);
		});
	}, []);

	return (
		<Template secondaryHeader icon={faTrophy} text="Torneios">
			<MenuTitle>
				Torneios de Warzone
			</MenuTitle>
			<Carousel height={350} >
				<>{tounaments && tounaments.map((tounament) => {
					return <SwiperSlide style={{ height: 340, width: 550 }} key={tounament.id}>
						<TournamentEntrance data={tounament} />
					</SwiperSlide>;
				})}</>
			</Carousel>
		</Template>
	);
};

export default Tournaments;