import * as React from "react";
import Button from "../../../../../components/UI/Buttons/Button";
import Feedback from "../../../../../components/UI/Feedback/Feedback";
import Input from "../../../../../components/UI/Inputs/Input/Input";
import Textarea from "../../../../../components/UI/Inputs/Textarea/textarea";
import RadioButton from "../../../../../components/UI/RadioButton/RadioButton";
import { setFeedbackMassage } from "../../../../../functions/feedbackFunctions";
import axiosInstance from "../../../../../services/axiosInstance";
import { SubTitle } from "./styles";

const GenericMatch = () => {
	const [loading, setLoading] = React.useState(false);

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);
		let category = "";
		if (document.querySelector("input[name='category']:checked")) {
			category = "generic";
		}
		const fd = new FormData(e.currentTarget);
		fd.append("game", "generic");
		fd.set("category", category);

		axiosInstance.post("/api/matches/solo", fd).then(() => {
			window.location.reload();
		}).catch((err) => {
			const errorMessage = err.response.data;
			setFeedbackMassage({ message: errorMessage.error, success: false, id: "createChallengeFeedback" });
		}).finally(() => {
			setLoading(false);
		});
	}

	return <form style={{ display: "flex", flexDirection: "column", marginTop: 5, marginBottom: 18, alignItems: "center", gap: 18 }} onSubmit={handleSubmit}>
		<Input name="title" placeholder="Street Fighter" required>
			Jogo
		</Input>
		<Input type="number" name="ticket" placeholder="5000" required>
			Valor (R$)
		</Input>
		<SubTitle>Categoria</SubTitle>
		<div style={{ display: "flex", width: "100%", justifyContent: "space-evenly", flexWrap: "wrap" }}>
			<RadioButton defaultChecked name="category">Genérico</RadioButton>
		</div>
		<Textarea name="description" placeholder="Você escolhe o mapa,e eu os personagens.">
			<span>Detalhes do seu desafio</span>
		</Textarea>
		<Feedback id="createChallengeFeedback" />
		<Button type="submit" loading={loading} disabled={loading}>CRIAR</Button>
	</form>;
};

export default GenericMatch;