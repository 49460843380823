import styled from "styled-components";
import colors from "../../../components/UI/constants/colors";

export const Container = styled.div`
    display: ${(props: {visible: boolean}) => props.visible ? "flex" : "none"};
    flex-direction: column;
    text-align: center;
`;

export const Title = styled.span`
    font-size: 26px;
    color: ${colors.WHITE};
    font-family: "Inter-Bold";
    margin-bottom: 5px;
`;