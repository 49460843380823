import styled from "styled-components";

export const Container = styled.div`
    display: ${(props: { isOpen: boolean }) => (props.isOpen ? "flex" : "none")};
    justify-content: start;
    align-items: center;
    flex-direction: column;
    gap: 18px;
`;

export const Form = styled.form`
    display: flex;
    max-width: 100%;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    gap: 18px;
`;