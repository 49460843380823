import styled from "styled-components";
import colors from "../../../constants/colors";

export const ChatHeader = styled.div`
  height: 100px;
	width: 100%;
	display: flex;
	align-items: center;
	padding-left: 15px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(50% - 47%);
    width: 95%;
    border-bottom: 1px solid ${colors.PRIMARY_GREEN};
  }
  
`;

export const ChatContainerText = styled.span`
	max-width: 370px;
	max-height: 40px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	color: ${colors.WHITE};
`;

export const ChatContainer = styled.div`
  width: 100%;
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
`;

export const MessageContainer = styled.div`
  display: flex;
  position: relative;
  padding: 10px;
  min-width: 225px;
  max-width: 400px;
  width: fit-content;
  margin-left: ${(props: { isMine: boolean }) => (props.isMine ? "auto" : "0px")};
  justify-content: flex-start;
  word-wrap: break-word;
  border: ${(props: { isMine: boolean }) => (props.isMine ? `1px solid ${colors.PRIMARY_GREEN}` : "none")};
  border-radius: ${(props: { isMine: boolean }) => (props.isMine ? "10px 10px 0px 10px" : "10px 10px 10px 0px")};
  background-color: ${colors.PRIMARY_GRAY};
`;

export const MessagesStyle = styled.div`
  display: flex; 
  flex-direction: column-reverse; 
  gap: 10px; 
  overflow-y: scroll; 
  height: 100%;

  ::-webkit-scrollbar {
      display: none;
  }
`;

export const MessageText = styled.span`
  color: ${colors.WHITE};
  min-width: 110px;
  padding-bottom: 15px;
  display: block;
  font-size: 15px;
`;

export const SendMessageContainer = styled.form`
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const DataLabel = styled.label`
  display: block;
  position: absolute;
  font-size: 14px;
  color: ${colors.WHITE};
  bottom: 4px;
  right: ${(props: { isMine: boolean }) => (props.isMine ? "auto" : "10px")};
  left: ${(props: { isMine: boolean }) => (props.isMine ? "10px" : "auto")};
`;