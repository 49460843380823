import * as React from "react";
import Button from "../../../../components/UI/Buttons/Button";
import colors from "../../../../components/UI/constants/colors";
import Feedback from "../../../../components/UI/Feedback/Feedback";
import Input from "../../../../components/UI/Inputs/Input/Input";
import { setFeedbackMassage } from "../../../../functions/feedbackFunctions";
import axiosInstance from "../../../../services/axiosInstance";
import { Transaction } from "../../../../types/Transaction";
import { Container, Form } from "./styles";

const TransferCurrency = (props: { visible: boolean, setTransactions: React.Dispatch<React.SetStateAction<Transaction[]>> }) => {
	const [loading, setLoading] = React.useState(false);
	const [value, setValue] = React.useState<number>(0);

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);
		const fd = new FormData(e.currentTarget);
		axiosInstance.post("/api/finance/gg-coins/transfer", fd).then(res => {
			if (res.status) {
				setFeedbackMassage({
					message: "Transação realizada com sucesso",
					success: true,
					id: "transferFeedback"
				});
			}
		})
			.catch(err => {
				if(err.response.data.error) {
					setFeedbackMassage({
						message: err.response.data.error,
						success: false,
						id: "transferFeedback"
					});
				} else {
					setFeedbackMassage({
						message: err.response.status,
						success: false,
						id: "transferFeedback"
					});
				}
			})
			.finally(() => {
				setLoading(false);
				axiosInstance.get("/api/finance/transactions"
				).then((res) => {
					if (res.data.data) {
						props.setTransactions(res.data.data);
					}
				});
			});
	}

	return <Container isOpen={props.visible}>
		<span style={{ fontSize: 32, color: colors.WHITE }}>TRANSFERIR MOEDAS</span>
		<Form onSubmit={e => handleSubmit(e)}>
			<Input onChange={(e) => setValue(parseFloat(e.currentTarget.value))} name="quantity" id="quantity2" placeholder="100.00" type="number">
				Quantidade
			</Input>
			<span style={{ textAlign: "start", fontStyle: "italic", color: colors.PRIMARY_GREEN, maxWidth: 315 }}>*As transferências têm taxa fixa de 2%. O valor que o usuário irá receber é {isNaN(parseFloat((value - (value * .02)).toFixed(2))) ? 0 : parseFloat((value - (value * 0.02)).toFixed(2))} .</span>
			<Input name="nickname" type="text" placeholder="Boneco_De_Treino">
				Nickname
			</Input>
			<Input type="password" id="password2" name="password" placeholder="**********">
				Senha
			</Input>
			<Feedback id="transferFeedback" />
			<Button type="submit" loading={loading} disabled={loading}>
				TRANSFERIR
			</Button>
		</Form>
	</Container>;
};

export default TransferCurrency;