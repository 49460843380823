import { useEffect, useRef } from "react";
import * as React from "react";
import { StyledInput, InputDiv } from "./styles";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    children: string;
    disabled?: boolean;
    defaultValue?: string;
    id?: string;
    mask?: string;
    placeholder?: string;
    value?: string | number | readonly string[];
    required?: boolean;
    onChange?: ((e: React.ChangeEvent<HTMLInputElement>) => void);
}

const ChatInput = (props: InputProps) => {
	const input = useRef<HTMLInputElement>(null);

	const hasInput = () => {
		if (input.current) {
			if (input.current.value === "" || props.value === "") {
				input.current.classList.add("no-input");
			}
			else {
				input.current.classList.remove("no-input");
			}
		}
	};

	useEffect(() => {
		hasInput();
	}, [input.current?.value, props.value]);

	return <InputDiv>
		<StyledInput
			type="text" 
			defaultValue={props.defaultValue}
			name={props.name}
			value={props.value}
			ref={input}
			id={props.id ? props.id : props.name}
			onInput={hasInput}
			onChange={(e) => {
				if (props.onChange) {
					props.onChange(e);
				}
			}}
			disabled={props.disabled}
			required={props.required}
			placeholder={props.placeholder}
			className={`${props.disabled ? "disabled" : ""}`}
			maxLength={255}
			minLength={1}
		/>
		<label htmlFor={props.id ? props.id : props.name}>{props.children}</label>
	</InputDiv>;
};

export default ChatInput;