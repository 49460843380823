import styled from "styled-components";
import colors from "../UI/constants/colors";
export const FooterContainer = styled.div`
    margin-top: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 65px;
    background-color: transparent;
`;

export const FooterLink = styled.a`
    cursor: pointer;
    color: ${colors.WHITE};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: ${colors.PRIMARY_GREEN};

    & span {
        margin-left: 5px;
    }

    & img {
        height: 28px;
    }

    @media (max-width: 768px) {
        & span {
            display: none;
        }
    }
`;