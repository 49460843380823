import styled from "styled-components";
import colors from "../UI/constants/colors";

export const Container = styled.header`
    height: 65px;
    padding: 0 18px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: ${colors.SECONDARY_BLACK};
`;

export const SecondaryContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    height: 65px;
    width: 100%;
    padding: 0 18px;
`;

export const BalanceButton = styled.button`
    background-color: transparent;
    color: ${colors.WHITE};
    font-size: 28px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:active {
        transform: scale(0.9);
    }
`;

export const NotificationsDiv = styled.div`
    position: absolute;
    display: none;
    border-radius: 10px 0px 10px 10px;
    right: 0px;
    z-index: 2;
    bottom: -305px;
    background-color: ${colors.SECONDARY_BLACK};
    box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.65);
    width: 280px;
    height: 300px;
    overflow-y: scroll;
`;

export const NotificationButton = styled.button`
    background-color: ${colors.SECONDARY_BLACK};
    display: flex;
    width: 100%;
    border: none;
    border-bottom: 2px solid ${colors.PRIMARY_GRAY};
    padding: 5px;
    z-index: 2;
    cursor: pointer;
    position: relative;
    transition: all 0.4s ease-in-out;

    &:active {
        transform: scale(0.9);
    }
`;

export const NotificationText = styled.span`
    color: ${colors.WHITE};
    font-size: 16px;
    padding: 5px;
    text-align: start;
    max-width: 100%;
    word-wrap: break-word;
`;