import axios from "axios";

export function returnPath() {
	const enviroment = process.env.REACT_APP_ENV;
	switch (enviroment) {
	case "production":
		return "https://api.gghub.com.br";
	case "staging":
		return "https://staging.api.gghub.com.br";
	case "development":
		return "http://localhost:8000";
	default:
		return;
	}
}

const axiosInstance = axios.create({
	baseURL: returnPath(),
	headers: {
		Authorization: "Bearer " + localStorage.getItem("gghub@user")
	}
});

export default axiosInstance;