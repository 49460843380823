const colors = {
	PRIMARY_BLACK: "#1D2229",
	SECONDARY_BLACK: "#20252C",
	THIRD_BLACK: "#2B3037",
	PRIMARY_GREEN: "#B3FF66",
	SECONDARY_GREEN: "#75AB3F",
	THIRD_GREEN: "#00F2AD",
	PRIMARY_GRAY: "#363C44",
	SECONDARY_GRAY: "#878E97",
	WHITE: "#FFFFFF",
	PRIMARY_RED: "#dc3545",
};

export default colors;