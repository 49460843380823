import { useState } from "react";
import Button from "../../../components/UI/Buttons/Button";
import colors from "../../../components/UI/constants/colors";
import Input from "../../../components/UI/Inputs/Input/Input";
import Textarea from "../../../components/UI/Inputs/Textarea/textarea";
import Modal from "../../../components/UI/Modal/Modal";
import axiosInstance from "../../../services/axiosInstance";

type reportWarningProps = {
	challengeId?: number;
	setVoted: (voted: boolean) => void;
}


const ReportWarning = (props: reportWarningProps) => {
	const [loading, setLoading] = useState<boolean>(false);

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		setLoading(true);
		e.preventDefault();
		const formData = new FormData(e.currentTarget);
		formData.append("vote", "2");
		axiosInstance.post(`/api/matches/solo/${props.challengeId}/vote`, formData).then(res => {
			if (res.status === 200) {
				props.setVoted(true);
				window.location.reload();
			}
		});
	}

	return <Modal id="reportWarning">
		<form onSubmit={(e) => handleSubmit(e)} encType="multipart/form-data" style={{ display: "flex", flexDirection: "column", gap: 20, alignItems: "center" }}>
			<div style={{ display: "flex", flexDirection: "column", gap: 10, alignItems: "center" }}>
				<span style={{
					fontSize: 32,
					color: colors.WHITE,
					fontFamily: "Inter-Bold"
				}}>REPORTAR DESAFIO</span>
				<span style={{
					fontSize: 26,
					color: colors.WHITE,
					textAlign: "center",
				}}>Se houve algum problema durante o desafio,
					envie-nos algumas informações para que nossa equipe de suporte avalie e forneça uma solução.</span>
			</div>
			<Textarea maxLength={255} name="description"><span>Descrição</span></Textarea>
			<Input name="external_video_url">Link de evidência &#40;URL&#41;</Input>
			<Input name="report_media" type="file" accept="image/png, image/jpg, image/webp">Arquivo de evidência</Input>
			<span style={{ color: colors.PRIMARY_GREEN, fontSize: 18, marginTop: -10, fontStyle: "italic" }}>*Envie uma print como evidência*</span>
			<Button disabled={loading} type="submit">ENVIAR</Button>
		</form>
	</Modal>;
};

export default ReportWarning;