import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "../../constants/colors";
import { OutlinedContainer } from "./styles";

type OutlineButtonProps = {
	children: string;
	disabled?: boolean;
	loading?: boolean;
	onClick?: () => void;
	danger?: boolean;
}

const OutlinedButton = (props: OutlineButtonProps) => {
	return <OutlinedContainer disabled={props.disabled || props.loading} className={`${props.disabled ? "disabled" : ""} ${props.danger ? "danger" : ""} ${props.loading ? "loading" : ""}`} onClick={props.onClick}>
		{props.loading ?
			<FontAwesomeIcon icon={faSpinner} className="fa-spin" size="2x" color={colors.WHITE}/>
			:
			<>
				<span>{props.children}</span>
			</>
		}</OutlinedContainer>;
};

export default OutlinedButton;