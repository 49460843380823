import { faTimes, faUserAstronaut, faUserDoctor, faUserNinja, faUserNurse, faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseMenu } from "../../../functions/menuFunctions";
import Button from "../../UI/Buttons/Button";
import IconButton from "../../UI/Buttons/IconButton";
import OutlinedButton from "../../UI/Buttons/OutlinedButton";
import colors from "../../UI/constants/colors";
import UserPic from "../../UI/UserPic/UserPic";
import { MenuActions, MenuHeader } from "../styles";

const UserPage = (props: { visible?: boolean, changePage: (page: string) => void; }) => {

	if (!props.visible) {
		return <></>;
	}

	function getRandomIcon() {
		const icons = [faUserAstronaut, faUserNinja, faUserDoctor, faUserSecret, faUserNurse, faUserDoctor];
		return icons[Math.floor(Math.random() * icons.length)];
	}

	return <>
		<MenuHeader >
			<IconButton className="close-menu" onClick={() => {
				CloseMenu();
			}} icon={faTimes} color={colors.PRIMARY_GREEN} nobackground />
		</MenuHeader>
		<MenuActions>
			<UserPic>
				<FontAwesomeIcon icon={getRandomIcon()} color={colors.SECONDARY_GRAY} size="4x" />
			</UserPic>
			<OutlinedButton onClick={() => props.changePage("LoginPage")}>
				ENTRAR
			</OutlinedButton>
			<Button onClick={() => props.changePage("SigninPage")}>
				CADASTRAR
			</Button>
		</MenuActions></>;
};

export default UserPage;