import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../../components/UI/Buttons/Button";
import colors from "../../../../../components/UI/constants/colors";
import { CloseModal } from "../../../../../functions/modalFunctions";
import axiosInstance from "../../../../../services/axiosInstance";
import { BattleNetUser } from "../../../../../types/BattleNetUser";
import { AccountName, ConfirmationDiv, Container } from "./styles";

const BattleNetContent = () => {
	const [code] = useState(new URLSearchParams(new URL(window.location.href).searchParams).get("code"));
	const [battleNetUser, setBattleNetUser] = useState<BattleNetUser>();

	const navigate = useNavigate();

	function NavigateTo(route: string) {
		window.hasPreviousUrl = true;
		navigate(route);
	}

	useEffect(() => {
		getAccessToken();
	}, [code]);

	function getAccessToken() {
		axiosInstance.get(`/api/auth/battlenet/token?code=${code}`).then(res => {
			if (res.status === 204) {
				getAccount();
			}
		});
	}

	function getAccount() {
		axiosInstance.get("/api/auth/battlenet/user").then(res => setBattleNetUser(res.data));
	}

	function removeUser() {
		axiosInstance.post("/api/auth/battlenet/remove-user").then(res => {
			if (res.status === 204) {
				CloseModal("connectAccountsModal");
				NavigateTo("/minha-conta");
				window.location.reload();
			}
		});
	}

	return <Container>

		{battleNetUser?.battletag ? <ConfirmationDiv>
			<span style={{ fontSize: 26, color: "#FFF" }}>Essa é sua conta?</span>
			<AccountName>{battleNetUser?.battletag}</AccountName>
			<div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
				<Button onClick={() => { CloseModal("connectAccountsModal"); NavigateTo("/minha-conta"); window.location.reload(); }}>CONFIRMAR</Button>
				<span style={{ color: colors.PRIMARY_GREEN, textDecoration: "underline", marginTop: 10 }} onClick={() => removeUser()}>recusar</span>
			</div>

		</ConfirmationDiv> : <div style={{ display: "flex", flexDirection: "column", gap: 10, color: colors.PRIMARY_GREEN }}>
			<span style={{ fontSize: 22 }}>Requisitando dados da conta</span>
			<FontAwesomeIcon icon={faSpinner} size="2x" color={colors.WHITE} className="fa-spin" style={{width: 50, margin: "auto"}}/>
		</div>
		}


	</Container>;
};

export default BattleNetContent;