import { createGlobalStyle, css } from "styled-components";
import InterRegular from "./assets/Fonts/Inter-Regular.ttf";
import InterBold from "./assets/Fonts/Inter-Bold.ttf";
import GalanoBold from "./assets/Fonts/GalanoGrotesqueBold.otf";
import colors from "./components/UI/constants/colors";


export const fontFaces = css`
  @font-face {
    font-family: 'Inter';
    src: url(${InterRegular}) format('truetype');
  }

  @font-face {
    font-family: 'Inter-Bold';
    src: url(${InterBold}) format('truetype');
  }

  @font-face {
    font-family: 'Galano-Bold';
    src: url(${GalanoBold}) format('opentype');
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${fontFaces}

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
  }

  body {
    background-color: ${colors.SECONDARY_BLACK}
  }

    /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::selection {
    background: ${colors.PRIMARY_BLACK};
    color: ${colors.PRIMARY_GREEN};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.PRIMARY_GREEN}; 
    border-radius: 5px;
  }

  ::-webkit-scrollbar-corner {
  background: rgba(0,0,0,0);
  } 

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.SECONDARY_GREEN}; 
  }
`;

