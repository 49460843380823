import * as React from "react";
import { Container, Title } from "../styles";
import { GameButton, Games } from "./styles";
import LolLogo from "../../../../assets/Images/LeagueOfLegendsLogo2.webp";
import WildRift from "../../../../assets/Icons/wildRift.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import colors from "../../../../components/UI/constants/colors";
import UserContext from "../../../../contexts/User";
// import TftLogo from "../../../../assets/Images/TFTLogo.png";

const ChooseGame = (props: { visible: boolean, changePage: React.Dispatch<React.SetStateAction<string>>, setGame: React.Dispatch<React.SetStateAction<string>> }) => {
	const userData = React.useContext(UserContext);

	return <Container visible={props.visible}>
		{userData && userData.user && userData.user.user ?
			<React.Fragment><Title>ESCOLHA UM JOGO</Title>
				<Games>
					<GameButton onClick={() => { props.setGame("lol"); return props.changePage("configs"); }}>
						<img src={LolLogo} alt="lol" width="70%" height="70%" />
					</GameButton>
					<GameButton onClick={() => { props.setGame("wildrift"); return props.changePage("configs"); }}>
						<img src={WildRift} alt="wildrift" width="90%" height="90%" />
					</GameButton>
					<GameButton onClick={() => { props.setGame("generic"); return props.changePage("configs"); }}>
						<FontAwesomeIcon icon={faPlus} size="2x" color={colors.PRIMARY_GREEN} />
					</GameButton>
					{/* <GameButton>
				<img src={TftLogo} alt="lol" width="45px" height="45px"/>
			</GameButton> */}
				</Games></React.Fragment> : <span style={{color: colors.PRIMARY_GREEN, fontSize: 22}}>Você precisa estar com uma conta para prosseguir.</span>}
	</Container>;
};

export default ChooseGame;