import { faFistRaised } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../../components/UI/Buttons/Button";
import colors from "../../../components/UI/constants/colors";
import Feedback from "../../../components/UI/Feedback/Feedback";
import Modal from "../../../components/UI/Modal/Modal";

type entryWarningProps = {
	loading: boolean;
	handleJoin: () => void;
}


const EntryWarning = (props: entryWarningProps) => {
	return <Modal id="entryWarning">
		<div style={{ display: "flex", flexDirection: "column", gap: 20, alignItems: "center" }}>
			<FontAwesomeIcon icon={faFistRaised} size="4x" color={colors.PRIMARY_GREEN} />
			<span style={{
				fontSize: 32,
				color: colors.WHITE,
				fontFamily: "Inter-Bold"
			}}>Entrar no desafio?</span>
			<span style={{
				fontSize: 22,
				color: colors.WHITE,
				textAlign: "start",
			}}>Atente-se às instruções:<br />
				<br />Grave sua partida se possível;<br />
				<br />Converse com seu oponente antes do PRONTO;<br />
				<br />Use sempre o CHAT;<br />
				<br />Para jogos com resultados não-automatizados, o resultado será votado pelos jogadores;<br />
			</span>
			<span style={{
				fontSize: 22,
				color: colors.PRIMARY_GREEN,
				textAlign: "center",
				fontStyle: "italic"
			}}>*Operação irreversível</span>
			<Button disabled={props.loading} onClick={() => props.handleJoin()}>DESAFIAR</Button>
			<Feedback id="entryWarningFeedback"/>
		</div>
	</Modal>;
};

export default EntryWarning;