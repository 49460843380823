import styled from "styled-components";
import colors from "../../../components/UI/constants/colors";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
`;

export const WinnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    & img {
        width: 75px;
        height: 75px;
        border-radius: 50%;
        border: 1px solid ${colors.PRIMARY_GREEN};
        margin-bottom: 18px;
    }
`;

export const Points = styled.span`
    font-size: 35px;
    color: ${colors.WHITE};
    font-family: "Inter", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    line-height: 12px;
`;

export const Prize = styled.span`
    position: absolute;
    top: 60px;
    right: 0px;
`;