import styled from "styled-components";
import colors from "../../components/UI/constants/colors";


export const FormContainer = styled.form`
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 20px;
`;

export const UserPicLabel = styled.label`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    z-index: 2;
`;

export const UserPicInput = styled.input`
    display: none;
`;

export const SectionTitle = styled.span`
    font-size: 30px;
    font-family: "Inter-Bold", sans-serif;
    color: ${colors.WHITE};
    margin-bottom: 15px;
`;

export const AccountsContainer = styled.div`
    width: 496px;
    max-width: 100%;
    padding: 18px;
    border-radius: 10px;
    border: 2px solid ${colors.PRIMARY_GREEN};
    margin-bottom: 30px;
`;

export const CenterContainer = styled.div`
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 20px;
`;

export const AccountContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;