import { useState, Dispatch, SetStateAction, useEffect } from "react";
import colors from "../../../../../../components/UI/constants/colors";
import axiosInstance from "../../../../../../services/axiosInstance";
import { CoinPackage } from "../../../../../../types/CoinPackage";
import { Container, MethodContainer } from "./styles";

const Packages = (props: { visible: boolean, changePage: Dispatch<SetStateAction<string>>, selectPackage: Dispatch<SetStateAction<CoinPackage | undefined>> }) => {
	const [packages, setPackages] = useState<CoinPackage[]>([]);

	useEffect(() => {
		axiosInstance.get("/api/shop/gg-coins").then(res => {
			setPackages(res.data);
		});
	}, []);

	return <Container isShowing={props.visible}>
		<div style={{ display: "flex", width: "100%", justifyContent: "center", gap: 30, flexWrap: "wrap" }}>{packages.map((coinPackage) => {
			return <MethodContainer
				key={coinPackage.id}
				onClick={() => {
					props.selectPackage(coinPackage); props.changePage("paymentTypes");
				}}
			>
				<div>
					<span style={{ fontSize: 32, marginLeft: 5 }}>{coinPackage.quantity}</span>
				</div>
				<span style={{color: colors.PRIMARY_GREEN, fontSize: 28}}>R$</span>
			</MethodContainer>;
		})}</div>
	</Container>;
};

export default Packages;