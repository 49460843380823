import { faTrophy, faMedal, faAward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "../../../components/UI/constants/colors";
import { WarzoneTournamentPlayer } from "../../../types/WarzoneTournamentPlayer";
import { Container, Points, Prize, WinnerContainer } from "./styled";

const Winner = (props: { ranking: WarzoneTournamentPlayer[] }) => {
	const { ranking } = props;

	const icon = [
		faTrophy,
		faMedal,
		faAward,
	];

	return <Container>
		{ranking.map((player: WarzoneTournamentPlayer, index) => {

			return <WinnerContainer key={index}>
				<div style={{ position: "relative", marginBottom: 2 }}>
					<img src={player.user_id.profile_picture} />
					<Prize><FontAwesomeIcon icon={icon[index]} size="2x" color={colors.THIRD_GREEN} /></Prize>
				</div>
				<span style={{ fontSize: 22, color: colors.WHITE }}><span style={{ color: colors.THIRD_GREEN }}>{index + 1 + "° "}</span> {player.user_id.nickname}</span>
				<Points>{player.points}<span style={{ fontSize: 16, position: "relative", bottom: -10 }}>pts.</span></Points>
			</WinnerContainer>;
		})}

	</Container>;
};

export default Winner;