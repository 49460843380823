import styled from "styled-components";
import colors from "../constants/colors";

export const ChallengeContainer = styled.div`
    overflow: hidden;

    &::before {
      content: "";
      border-radius: 10px;
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.2;
      background-image: url(${(props: { background?: string }) => props.background});
    }
`;

export const ChallengeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  font-size: 0px;
  height: 116px;
  align-items: center;
  padding: 0 16px;
    
    & div.user-data {
      display: flex; 
      flex-direction: column;
      justify-content: center;
      max-width: 327px;
    }
  
    & span {
      margin-left: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & span.user-name {
      color: ${colors.WHITE};
      font-size: 18px;
    }

    & span.challange-name {
      color: ${colors.WHITE};
      font-size: 26px;
      font-family: "Inter-Bold", sans-serif;
    }

    & img.game-logo {
        width: 100px;
        display: block;
    }

    & img.user-picture {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
`;

export const UserSpace = styled.div`
  display: flex;
  align-items: center;
`;

export const ChallangeDiv = styled.div`
    position: relative;
    background-color: ${colors.PRIMARY_GRAY};
    border-radius: 10px 10px 0 0;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
`;

export const ChallangeData = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-height: 147px;
`;

export const Topic = styled.span`
    font-size: 22px;
    margin-bottom: 10px;
    color: ${colors.WHITE};
    font-family: "Inter-Bold", sans-serif;
`;

export const Value = styled.span`
    font-size: 22px;
    color: ${colors.WHITE};
    font-family: "Inter", sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const DataDiv = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const PrizeDiv = styled.div`
    display: flex;
    height: 100%;
    text-align: center;
    flex-direction: column;

    & .prize {
      font-size: 22px;
      margin-bottom: 10px;
      color: ${colors.WHITE};
      font-family: "Inter-Bold", sans-serif;
      text-align: center;
    }
`;