import { useState } from "react";
import { CoinPackage } from "../../../../types/CoinPackage";
import Packages from "./pages/Packages/customerData";
import PaymentTypes from "./pages/PaymentTypes/paymentTypes";
import PixPage from "./pages/PixPage/pixPage";
import { Container } from "./styles";

const AddCurrency = (props: { visible: boolean }) => {
	const [page, setPage] = useState<string>("packages");
	const [coinPackage, setCoinPackage] = useState<CoinPackage | undefined>(undefined);

	return <Container isOpen={props.visible}>
		<Packages visible={page === "packages"} changePage={setPage} selectPackage={setCoinPackage}/>
		<PaymentTypes visible={page === "paymentTypes"} changePage={setPage} />
		<PixPage visible={page === "pixPage"} changePage={setPage} coinPackage={coinPackage}/>
	</Container>;
};

export default AddCurrency;