import { CloseModal } from "../../../functions/modalFunctions";
import { MenuContainer, ModalBackground, ModalContent } from "./styles";

const Modal = (props: { id: string, children: JSX.Element[] | JSX.Element }) => {
	return (
		<MenuContainer id={props.id}>
			<ModalBackground onClick={() => {
				CloseModal(props.id);
			}} />
			<ModalContent>
				{props.children}
			</ModalContent>
		</MenuContainer>
	);
};

export default Modal;