import { faChevronLeft, faChevronRight, faCoins, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Template from "../../components/Template";
import Button from "../../components/UI/Buttons/Button";
import IconButton from "../../components/UI/Buttons/IconButton";
import OutlinedButton from "../../components/UI/Buttons/OutlinedButton";
import colors from "../../components/UI/constants/colors";
import Feedback from "../../components/UI/Feedback/Feedback";
import Modal from "../../components/UI/Modal/Modal";
import UserContext from "../../contexts/User";
import { setFeedbackMassage } from "../../functions/feedbackFunctions";
import getFormatedDate from "../../functions/getFormatedDate";
import getFormatedTime from "../../functions/getFormatedTime";
import { OpenModal } from "../../functions/modalFunctions";
import seo from "../../helpers/seo";
import axiosInstance from "../../services/axiosInstance";
import { Videos } from "../../types/Videos";
import ConnectAccount from "../Account/ModalContents/ConnectAccount/ConnectAccount";
import Player from "./Player";
import { Banner, Controllers, Details, PlayContainer, PlayerPoints, PlayersContainer, Scoreboard, Title, Topic, Value, Video } from "./styles";
import Winner from "./Winner";
import { WarzoneTournaments } from "../../types/WarzoneTournaments";
import { WarzoneTournamentPlayer } from "../../types/WarzoneTournamentPlayer";

const Tournament = () => {
	const { id } = useParams();
	const [tournament, setTournament] = useState<WarzoneTournaments>();
	const Data = useContext(UserContext);
	const [myPosition, setMyPosition] = useState<WarzoneTournamentPlayer | undefined>(tournament?.players.find((player: WarzoneTournamentPlayer) => (player.user_id.id === Data?.user?.user.id)));
	const [videos] = useState<Videos[]>([{ slug: "IncredulousAbstemiousFennelImGlitch" }, { slug: "AntsySuspiciousWeaselPicoMause-5Rjf4t5v-thw5q1m" }]);
	const [ranking, setRanking] = useState<WarzoneTournamentPlayer[]>([]);
	const [index, setIndex] = useState<number>(1);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		axiosInstance.get(`/api/tournament/warzone/show/${id}`).then((response) => {
			setTournament(response.data);
			seo({
				title: response.data.title
			});
		});
	}, [Data?.user]);

	useEffect(() => {
		axiosInstance.get(`/api/tournament/warzone/ranking/${id}`).then((response) => {
			setRanking(response.data);
		});
	}, []);

	useEffect(() => {
		setMyPosition(tournament?.players.find((player: WarzoneTournamentPlayer) => (player.user_id.id === Data?.user?.user.id)));
	}, [Data?.user, tournament]);

	function handleRefund() {
		setLoading(true);
		axiosInstance.post("/api/tournament/warzone/refund", {
			tournament_id: tournament?.id,
		}).then((response) => {
			if (response.status === 200) {
				location.reload();
			}
		}).catch((error) => {
			setFeedbackMassage({ message: error.code, success: false, id: "refundFeedback" });
		}).finally(() => setLoading(false));
	}

	function handleJoin() {
		setLoading(true);
		axiosInstance.post("/api/tournament/warzone/join", {
			tournament_id: tournament?.id,
		}).then((response) => {
			if (response.status === 200) {
				location.reload();
			}
		}).catch((error) => {
			setFeedbackMassage({ message: error.message, success: false, id: "entryFeedback" });
		}).finally(() => setLoading(false));
	}

	const handleCheckUser = () => {
		if (Data?.user === undefined) {
			return setFeedbackMassage({ message: "Você precisa estar logado para participar de um torneio.", success: false, id: "entryFeedback" });
		}
		else if (Data?.user.battle_net_account === null) {
			return setFeedbackMassage({ message: "Você precisa conectar sua conta Battle Net.", success: false, id: "entryFeedback" });
		}
		handleJoin();
	};

	function returnParent() {
		const enviroment = process.env.REACT_APP_ENV;
		switch (enviroment) {
		case "production":
			return "app.gghub.com.br";
		case "staging":
			return "app-gghub.herokuapp.com";
		case "development":
			return "localhost";
		default:
			return;
		}
	}

	return <Template secondaryHeader icon={faTrophy} text="Torneio">
		<Modal id="giveup">
			<div style={{ textAlign: "center" }}>
				<span style={{ fontSize: 24, margin: "auto", color: colors.WHITE }}>Ao desistir você será removido do Torneio e seus fundos seram reembolsados. <br /><span style={{ fontSize: 24, margin: "auto", color: colors.PRIMARY_RED }}>Tem certeza que deseja desistir?</span></span>
			</div>
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 18, flexDirection: "column" }}>
				<OutlinedButton danger onClick={() => { handleRefund(); }} loading={loading}>
					DESISTIR
				</OutlinedButton>
				<Feedback id="refundFeedback" />
			</div>
		</Modal>
		<Banner><span>{tournament?.title}</span></Banner>
		<Modal id="connectRiotAccount">
			<ConnectAccount type="Riot Games" />
		</Modal>
		<Details>
			<Topic>Modo: <Value>{tournament?.gamemode}</Value></Topic>
			<Topic>Players: <Value>{tournament?.players_count}</Value></Topic>
			<Topic>Vagas: <Value>{tournament?.slots}</Value></Topic>
			<Topic>Início: <Value>{getFormatedDate(tournament?.start_at || "") + " " + getFormatedTime(tournament?.start_at || "")}</Value></Topic>
			<Topic>Término: <Value>{getFormatedDate(tournament?.end_at || "") + " " + getFormatedTime(tournament?.end_at || "")}</Value></Topic>
			<Topic>Status: <Value style={{ color: tournament?.status === "Aberto" ? colors.PRIMARY_GREEN : colors.PRIMARY_RED }}>{tournament?.status}</Value></Topic>
			<Topic>Prêmio máximo: <Value><FontAwesomeIcon color={colors.THIRD_GREEN} icon={faCoins} size="1x" style={{ marginRight: 5 }} />{tournament?.slots && tournament?.ticket ? tournament?.slots * tournament?.ticket : "0"}</Value></Topic>
			<Topic>Entrada: <Value><FontAwesomeIcon color={colors.THIRD_GREEN} icon={faCoins} size="1x" style={{ marginRight: 5 }} />{tournament?.ticket}</Value></Topic>
		</Details>
		<div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", rowGap: 10, columnGap: 60, justifyContent: "center" }}>
			{ranking.length && tournament?.status === "Fechado" ? <Scoreboard>
				<Title>Vencedores</Title>
				<Winner ranking={ranking} />
			</Scoreboard> : <></>}
			{videos && <PlayContainer>
				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
					<Title>Destaques</Title>
					<span style={{ fontSize: 30, color: colors.PRIMARY_GREEN }}>#{index}/{videos.length}</span>
				</div>
				<Video src={`https://clips.twitch.tv/embed?clip=${videos[index - 1].slug}&parent=${returnParent()}&autoplay=true`} frameBorder="0" allowFullScreen scrolling="no" />
				<Controllers>
					<IconButton nobackground icon={faChevronLeft} onClick={() => setIndex(index - 1)} color={colors.PRIMARY_GREEN} disabled={index === 1} />
					{myPosition && <Player player={myPosition} position={1} />}
					<IconButton color={colors.PRIMARY_GREEN} nobackground icon={faChevronRight} onClick={() => setIndex(index + 1)} disabled={index === videos.length} />
				</Controllers>
			</PlayContainer>}
			<PlayersContainer>
				<>
					{tournament && myPosition ? <div style={{ gap: 18, display: "flex", flexDirection: "column" }}>
						<Title>Sua posição</Title>
						{myPosition && <div style={{ backgroundColor: colors.PRIMARY_GRAY, padding: 18, borderRadius: 10, width: 550,
							maxWidth: "100%" }}><Player player={myPosition} position={tournament.players.findIndex((player: WarzoneTournamentPlayer) => (player.user_id.id === myPosition.user_id.id)) + 1} /></div>}

					</div> : <div style={{ maxWidth: 300 }}><Button onClick={() => { handleCheckUser(); }} loading={loading}>PARTICIPAR</Button><Feedback id="entryFeedback" /></div>}</>
				<Title>Ranking Geral</Title>
				<PlayerPoints>
					{tournament?.players.map((player, index) => <div style={{ display: "flex", flexDirection: "column", gap: 0 }} key={index}><Player player={player} position={index + 1} /></div>)}
				</PlayerPoints>
			</PlayersContainer>
		</div>
		{myPosition ? <div style={{ margin: "auto", marginTop: 18 }}>
			<OutlinedButton danger onClick={() => { OpenModal("giveup"); }} >
				DESISTIR
			</OutlinedButton>
		</div> : <></>}
	</Template>;
};

export default Tournament;  