import * as React from "react";
import { CheckBoxStyle } from "./styles";

type CheckBoxButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
	children: React.ReactNode | React.ReactNode[];
	checked?: boolean;
	id: string;
	disabled?: boolean;
	onChange?: ((e: React.ChangeEvent<HTMLInputElement>) => void);
	readOnly?: boolean;
	name?: string;
};

const CheckBoxButton = (props: CheckBoxButtonProps) => {
	return (
		<CheckBoxStyle>
			<label className={`container ${props.disabled ? "disabled" : ""}`}>{props.children}
				<input readOnly={props.readOnly} type="checkbox" checked={props.checked} disabled={props.disabled} id={props.id} onChange={props.onChange} name={props.name}/>
				<span className={`checkmark ${props.disabled ? "disabled" : ""}`}></span>
			</label>
		</CheckBoxStyle>
	);
};

export default CheckBoxButton;
