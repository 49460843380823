import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useContext } from "react";
import colors from "../../../components/UI/constants/colors";
import UserContext from "../../../contexts/User";
import getRandomIcon from "../../../functions/getRandomIcon";
import { User } from "../../../types/User";
import { Container, PlayerImg, Points, Position, Username } from "./styles";
import { WarzoneTournamentPlayer } from "../../../types/WarzoneTournamentPlayer";

const Player = (props: { player: WarzoneTournamentPlayer, position: number }) => {
	const { user_id } = props.player;
	const [myUser, setMyUser] = useState<User>();
	const Data = useContext(UserContext);

	useEffect(() => {
		setMyUser(Data?.user);
	}, [user_id]);

	return <Container myPosition={myUser?.user && user_id ? myUser.user.id === user_id.id : false}>
		<Position>
			{"#" + props.position}
		</Position>
		<PlayerImg>
			{user_id.profile_picture ? <img src={user_id.profile_picture} alt="avatar" id="avatar" /> : <FontAwesomeIcon icon={getRandomIcon()} size="5x" color={colors.WHITE} />}
		</PlayerImg>
		<Username>{user_id && user_id.nickname}</Username>
		<Points>{props.player.points}<span style={{ fontSize: 16, position: "relative", bottom: -10 }}>pts.</span></Points>
	</Container>;
};

export default Player;