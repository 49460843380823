import * as React from "react";
import { RadioStyled } from "./styles";

type RadioButtonProps = {
	children: React.ReactNode;
	checked?: boolean;
	id?: string;
	name: string;
	disabled?: boolean;
	defaultChecked?: boolean;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const RadioButton = (props: RadioButtonProps) => {
	return (
		<RadioStyled>
			<label className={`container ${props.disabled ? "disabled" : ""}`}>{props.children}
				<input defaultChecked={props.defaultChecked} type="radio" id={props.id} checked={props.checked} disabled={props.disabled} onChange={props.onChange} name={props.name} />
				<span className={`checkmark ${props.disabled ? "disabled" : ""}`}></span>
			</label>
		</RadioStyled>
	);
};

export default RadioButton;
