import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { CloseMenu } from "../../../../functions/menuFunctions";
import Button from "../../../UI/Buttons/Button";
import IconButton from "../../../UI/Buttons/IconButton";
import colors from "../../../UI/constants/colors";
import { MenuHeader } from "../../styles";

const ConfirmPage = (props: { visible?: boolean, changePage: (page: string) => void; }) => {

	if (!props.visible) {
		return <></>;
	}

	return <>
		<MenuHeader>
			<IconButton className="close-menu" onClick={() => {
				CloseMenu();
			}} icon={faTimes} color={colors.PRIMARY_GREEN} nobackground />
		</MenuHeader>
		<div style={{ display: "flex", alignItems: "center", marginTop: 200, padding: 18, textAlign: "center", flexDirection: "column", gap: 18 }}>
			<span style={{ fontSize: 18, color: colors.PRIMARY_GREEN }}>Sua conta na GGHub está quase pronta. Para ativá-la, por favor confirme o seu endereço de email.</span>
			<Button onClick={() => {
				props.changePage("LoginPage");
			}}>CONTINUAR
			</Button>
		</div>
	</>;
};

export default ConfirmPage;