import styled from "styled-components";
import colors from "../../../components/UI/constants/colors";

export const Container = styled.div`
    display: flex;
    border: ${(props: {myPosition: boolean}) => (props.myPosition ? "1px solid " + colors.PRIMARY_GREEN : "none")};
    height: 80px;
    padding: 10px;
    max-width: 100%;
    min-width: 270px;
    justify-content: space-between;
    background-color: ${colors.SECONDARY_BLACK};
    border-radius: 5px;

    @media (max-width: 768px) {
        min-width: 0px;
    }
`;

export const Position = styled.span`
    font-size: 35px;
    color: ${colors.WHITE};
    font-family: "Inter-Bold", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PlayerImg = styled.div`
    margin-left: 25px;

    & img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }

    @media (max-width: 375px) {
        display: none;
    }
`;

export const Username = styled.span`
    font-size: 30px;
    margin: auto;
    margin-left: 5px;
    color: ${colors.WHITE};
    font-family: "Inter-Bold", sans-serif;

    @media (max-width: 550px) {
        font-size: 20px;
    }
`;

export const Points = styled.span`
    font-size: 35px;
    color: ${colors.WHITE};
    font-family: "Inter", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    line-height: 12px;

    @media (max-width: 550px) {
        font-size: 25px;
    }
`;