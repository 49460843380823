import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import * as React from "react";
import Header from "../../components/Header";
import { setFeedbackMassage } from "../../functions/feedbackFunctions";
import { CloseModal, OpenModal } from "../../functions/modalFunctions";
import axiosInstance from "../../services/axiosInstance";
import Footer from "../Footer/Footer";
import Menu from "../Menu/Menu";
import Button from "../UI/Buttons/Button";
import colors from "../UI/constants/colors";
import Feedback from "../UI/Feedback/Feedback";
import Input from "../UI/Inputs/Input/Input";
import Modal from "../UI/Modal/Modal";
import { Container } from "./styles";
import { useNavigate } from "react-router-dom";
import Chat from "../UI/Chat/Chat";

declare global {
	interface Window { hasPreviousUrl: boolean; }
}

const Template = ({ children, secondaryHeader, text, icon }: { children: JSX.Element[] | JSX.Element, secondaryHeader?: boolean, text?: string | null, icon?: FontAwesomeIconProps["icon"] | null }) => {
	const [passwordToken, setPasswordTokenoken] = React.useState<string | null>(null);
	const [emailConfirmationToken, setEmailConfirmationToken] = React.useState<string | null>(null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const navigate = useNavigate();

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		setPasswordTokenoken(urlParams.get("recovery_password_token"));
		setEmailConfirmationToken(urlParams.get("email_confirmation_token"));
	}, []);

	window.hasPreviousUrl = window.hasPreviousUrl || false;

	useEffect(() => {
		if (passwordToken) {
			OpenModal("recoveryPassword");
		}
		if (emailConfirmationToken) {
			OpenModal("emailConfirmation");
		}
	}, [passwordToken, emailConfirmationToken]);

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);
		const form = e.currentTarget;
		const fd = new FormData(form);
		const password = fd.get("password");
		const rePassword = fd.get("re-password");
		if (passwordToken) {
			fd.append("token", passwordToken);
		}
		if (password === rePassword) {
			axiosInstance.post(`/api/recovery-password/${passwordToken}`, fd).then(res => {
				if (res.data.error) {
					setFeedbackMassage({ message: res.data.error, success: false, id: "changePasswordFeedback" });
				}
				else {
					setFeedbackMassage({ message: "Alterado com sucesso, o token ficará inválido", success: true, id: "changePasswordFeedback" });
					setTimeout(() => {
						window.location.reload();
					}, 3000);
				}
			}).catch(err => {
				setFeedbackMassage({ message: err.response.status, success: false, id: "changePasswordFeedback" });
			}).finally(() => {
				setLoading(false);
			});
		}
		else {
			setFeedbackMassage({
				message: "Senhas não conferem",
				success: false,
				id: "changePasswordFeedback"
			});
			setLoading(false);
		}
	}

	return <Container>
		<Menu />
		<Chat />
		<Modal id="recoveryPassword">
			<form style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 18 }} onSubmit={e => handleSubmit(e)}>
				<span style={{ fontSize: 33, color: colors.WHITE }}>Trocar senha</span>
				<Input name="password" type="password" id="newPassword">
					Nova senha
				</Input>
				<Input name="re-password" id="rePassword" type="password">
					Repita a senha
				</Input>
				<Feedback id="changePasswordFeedback" />
				<Button type="submit" disabled={loading} loading={loading}>
					TROCAR
				</Button>
			</form>
		</Modal>
		<Modal id="emailConfirmation">
			<div style={{ display: "flex", justifyContent: "center", textAlign: "center", flexDirection: "column", gap: 20, alignItems: "center" }}>
				<span style={{ fontSize: 33, color: colors.WHITE }}>Seu email foi<br /><span style={{ color: colors.PRIMARY_GREEN }}>confirmado</span><br />com sucesso!</span>
				<Button onClick={() => { CloseModal("emailConfirmation"); navigate("/"); }}>FECHAR</Button>
			</div>
		</Modal>
		<Header secondary={secondaryHeader} text={text} icon={icon} />
		{children}
		<Footer />
	</Container>;
};

export default Template;