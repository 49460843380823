import { Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

const Carousel = ({ children, height }: { children: JSX.Element | JSX.Element[], height: number }) => {


	return (
		<Swiper
			slidesPerView="auto"
			spaceBetween={15}
			pagination={{
				clickable: true,
			}}
			height={height}
			style={{width: "100%", paddingLeft: 51}}
			className="mySwiper"
		>
			{children}
		</Swiper>
	);
};

export default Carousel;