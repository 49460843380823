import styled from "styled-components";
import colors from "../../../../../components/UI/constants/colors";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 18px;
    display: flex;
    justify-content: center;
`;

export const AccountName = styled.span`
    font-size: 26px;
    color: ${colors.WHITE};
    font-family: "Inter-Bold"
`;

export const ConfirmationDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
`;