import { faChevronLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { setFeedbackMassage } from "../../../../functions/feedbackFunctions";
import { CloseMenu } from "../../../../functions/menuFunctions";
import axiosInstance from "../../../../services/axiosInstance";
import Button from "../../../UI/Buttons/Button";
import IconButton from "../../../UI/Buttons/IconButton";
import colors from "../../../UI/constants/colors";
import Feedback from "../../../UI/Feedback/Feedback";
import Input from "../../../UI/Inputs/Input/Input";
import { Form, MenuActions, MenuHeader } from "../../styles";

const RequestPage = (props: { visible?: boolean, changePage: (page: string) => void; }) => {
	const [loading, setLoading] = React.useState(false);

	if (!props.visible) {
		return <></>;
	}

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading(true);
		const fd = new FormData(event.currentTarget);
		axiosInstance.post("/api/recovery-password", fd).then(res => {
			if(res.data.success === true) {
				setFeedbackMassage({ message: res.data.message, success: true, id: "recoveryPassword" });
			}
		}).catch((error) => {
			if(error.response.data.message) {
				setFeedbackMassage({ message: error.response.data.message, success: false, id: "recoveryPassword" });
			}
			else {
				setFeedbackMassage({ message: error.response.status, success: false, id: "recoveryPassword" });
			}
		}).finally(() => {
			setLoading(false);
		});
	};

	return <>
		<MenuHeader className="two-icons">
			<IconButton onClick={() => props.changePage("UserPage")} icon={faChevronLeft} className="back-button" color={colors.PRIMARY_GREEN} nobackground />
			<IconButton className="close-menu" onClick={() => {
				CloseMenu();
			}} icon={faTimes} color={colors.PRIMARY_GREEN} nobackground />
		</MenuHeader>
		<MenuActions>
			<span style={{fontSize: 26, color: colors.WHITE, fontFamily: "Inter-Bold"}}>RECUPERAR SENHA</span>
			<Form onSubmit={handleSubmit}>
				<Input name="email">Email</Input>
				<Feedback id="recoveryPassword" />
				<Button type="submit" disabled={loading} loading={loading}>ENVIAR</Button>
			</Form>
		</MenuActions>
	</>;
};

export default RequestPage;