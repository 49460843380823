import { useEffect } from "react";
import Template from "../../../components/Template";
import seo from "../../../helpers/seo";
import { Object } from "./styles";

const UserTermsAndPrivacy = () => {
	useEffect(()=>{
		seo({
			title: "Termos de Uso e Privacidade"
		});
	}, []);
	return <Template>
		<Object data="/docs/legal/termos-de-uso-e-privacidade.pdf" type="application/pdf">
			<p>Seu navegador não suporta PDF.</p>
		</Object>
	</Template>;
};

export default UserTermsAndPrivacy;