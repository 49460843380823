import * as React from "react";
import colors from "../../../../components/UI/constants/colors";
import { Container, Title } from "../styles";
import LolMatch from "./LolMatch";
import GenericMatch from "./GenericMatch";
import WildRiftMatch from "./WildRiftMatch";

const Configs = (props: { visible: boolean, changePage: React.Dispatch<React.SetStateAction<string>>, game: string }) => {

	function returnGame() {
		switch (props.game) {
		case "lol":
			return "League of Legends";
		case "wildrift": 
			return "Wild Rift";
		case "generic":
			return "Genérico";
		default: 
			return;
		}
	}

	return <Container visible={props.visible}>
		<Title>DETALHES DO DESAFIO</Title>
		<Title>{returnGame()}</Title>
		{props.game === "lol" && <LolMatch />}
		{props.game === "wildrift" && <WildRiftMatch />}
		{props.game === "generic" && <GenericMatch />}
		<span style={{ color: colors.PRIMARY_GREEN, textDecoration: "underline", fontSize: 18, cursor: "pointer" }} onClick={() => { return props.changePage("chooseGame"); }}>voltar</span>
	</Container>;
};

export default Configs;