import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, Dispatch, SetStateAction, useEffect } from "react";
import colors from "../../../../../../components/UI/constants/colors";
import { Container, Hash, HashContainer, PixQRCode } from "./styles";
import PixLogo from "../../../../../../assets/Icons/pix-106.svg";
import { CoinPackage } from "../../../../../../types/CoinPackage";
import axiosInstance from "../../../../../../services/axiosInstance";
import { PixPayment } from "../../../../../../types/PixPayment";


const PixPage = (props: { visible: boolean, changePage: Dispatch<SetStateAction<string>>, coinPackage: CoinPackage | undefined }) => {
	const [data, setData] = useState<PixPayment | undefined>();

	useEffect(() => {
		if (props.coinPackage) {
			axiosInstance.post("/api/payment/pix", {
				item: props.coinPackage.id,
			}).then(res => {
				setData(res.data);
			});
		}
	}, [props.coinPackage]);


	return <Container isOpen={props.visible}>
		<PixQRCode src={data?.base64 || PixLogo} />
		<HashContainer>
			<Hash disabled value={data?.codigo} />
			<FontAwesomeIcon icon={faClipboard} size="lg" style={{ alignSelf: "center", margin: "auto", cursor: "pointer" }} color={colors.PRIMARY_GREEN} onClick={() => {
				if (data) {
					navigator.clipboard.writeText(data.codigo);
				}
			}} />
		</HashContainer>
		<span style={{ color: colors.PRIMARY_GREEN, textDecoration: "underline", fontSize: 18, cursor: "pointer" }} onClick={() => props.changePage("paymentTypes")}>
			voltar
		</span>
	</Container>;
};

export default PixPage;