import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import * as React from "react";
import colors from "../../constants/colors";
import { IconButtonStyles } from "./styles";

interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	children?: string;
	icon: FontAwesomeIconProps["icon"];
	color?: string;
	disabled?: boolean;
	loading?: boolean;
	nobackground?: boolean;
	ariaLabel?: string;
	onClick?: () => void;
	type?: "button" | "submit" | "reset";
	className?: string;
	styles?: React.CSSProperties;
}

const IconButton = (props: IconButtonProps) => {
	const changeSize = props.nobackground ? "2x" : "1x";
	const addClass = props.nobackground ? "nobackground" : "";
	const color = props.disabled || props.loading ? colors.PRIMARY_GRAY : props.color;

	return <IconButtonStyles
		style={props.styles}
		type={props.type}
		id={props.id}
		role="button"
		aria-label={props.ariaLabel}
		className={`${props.loading ? "loading" : ""}${props.disabled ? "disabled" : ""} ${addClass} ${props.className ? props.className : ""}`}
		disabled={props.disabled || props.loading} onClick={props.onClick}>
		{props.loading ?
			<FontAwesomeIcon icon={faSpinner} color={colors.WHITE} className="fa-spin" size="2x" />
			:
			<>
				<FontAwesomeIcon icon={props.icon} color={color} size={changeSize} className="icon"/>
				{props.children && <span>{props.children}</span>}
			</>
		}
	</IconButtonStyles>;
};

export default IconButton;