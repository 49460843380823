import styled from "styled-components";
import colors from "../constants/colors";

export const UserPicDiv = styled.div`
    width: 120px;
    height: 120px;
    position: relative;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.SECONDARY_BLACK};

    &.backgroundColored {
        background-color: ${colors.PRIMARY_GRAY}
    }

    &.clickable {
        cursor: pointer;
    }

    & img {
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 50%;
    }
`;