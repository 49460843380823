import styled from "styled-components";
import colors from "../../../../../../components/UI/constants/colors";

export const Container = styled.div`
    display: ${(props: {isShowing: boolean}) => (props.isShowing ? "flex" : "none")};
    width: 100%;
`;

export const MethodContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    justify-content: end;
    align-items: center;
    width: 150px;
    height: 150px;
    border: 1px solid ${colors.PRIMARY_GREEN};
    border-radius: 10px;
    transition: all 0.2s ease-in-out;

    & span {
        font-size: 1.5rem;
        margin-top: 15px;
        color: ${colors.PRIMARY_GREEN};
    }

    &:hover {
        cursor: pointer;
    }

    &:active {
        transform: scale(0.95);
    }
`;