import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonStyles } from "./styles";

type ButtonProps = {
	children?: string;
	disabled?: boolean;
	loading?: boolean;
	onClick?: () => void;
	type?: "button" | "submit" | "reset";
}

const Button = (props: ButtonProps) => {
	return <ButtonStyles
		className={`${props.disabled ? "disabled" : ""} ${props.loading ? "loading" : ""}`}
		disabled={props.disabled || props.loading} onClick={props.onClick} type={props.type}>
		{props.loading ?
			<FontAwesomeIcon icon={faSpinner} className="fa-spin" size="2x" />
			:
			props.children
		}
	</ButtonStyles>;
};

export default Button;