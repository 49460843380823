import styled from "styled-components";
import colors from "../../constants/colors";

export const IconButtonStyles = styled.button`
  background: ${colors.PRIMARY_GREEN};
  color: ${colors.PRIMARY_BLACK};
  font-family: 'Inter-Bold', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: 178px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &.outlined {
    border: 2px solid ${colors.PRIMARY_GREEN};
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }

  &.outlined .icon {
    font-size: 22px;
  }

  &.outlined span {
    font-size: 22px;
    font-family: "Inter", sans-serif;
    color: ${colors.WHITE}
  }

  &.nobackground {
    width:  fit-content;
    height: fit-content;
    background-color: transparent;
  }

  &.nobackground span {
    font-size: 28px;
    color: ${colors.WHITE};
  }

  &.nobackground:hover {
    background: transparent;
    
  }

  &:hover {
    background: ${colors.SECONDARY_GREEN};
  }

  &:active {
    transform: scale(0.9);
  }

  &.disabled {
    color: ${colors.PRIMARY_GRAY};
    cursor: not-allowed;
    padding: 0;
  }

  &.loading {
    background: ${colors.PRIMARY_GREEN};
    color: ${colors.WHITE};
    cursor: progress;
  }

  &.loading:active {
    transform: none;
  }

  & span {
    margin-left: 5px;
    font-family: 'Inter-Bold', sans-serif;
    font-size: 16px;
  }
`;