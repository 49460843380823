import * as React from "react";
import Button from "../../../../../components/UI/Buttons/Button";
import CheckBoxButton from "../../../../../components/UI/Buttons/CheckBoxButton";
import Feedback from "../../../../../components/UI/Feedback/Feedback";
import Input from "../../../../../components/UI/Inputs/Input/Input";
import RadioButton from "../../../../../components/UI/RadioButton/RadioButton";
import { setFeedbackMassage } from "../../../../../functions/feedbackFunctions";
import axiosInstance from "../../../../../services/axiosInstance";
import { SubTitle } from "./styles";

const LolMatch = () => {
	const [loading, setLoading] = React.useState(false);
	const [map, setMap] = React.useState("summonersrift");
	const [title, setTitle] = React.useState("");
	const [pickMode, setPickMode] = React.useState("draft");
	const [ticket, setTicket] = React.useState<number | string>("");
	const [firstBlood, setFirstBlood] = React.useState(false);
	const [firstTower, setFirstTower] = React.useState(false);
	const [goldLimit, setGoldLimit] = React.useState(false);
	const [canoutlane, setCanOutLane] = React.useState(false);
	const [cantrecall, setCanTRecall] = React.useState(false);

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);
		const data = {
			"game": "wildrift",
			"title": title,
			"map": map,
			"pickmode": pickMode,
			"ticket": ticket,
			"win_conditions": [
				"destroynexus",
				firstBlood ? "firstblood" : "",
				firstTower ? "firsttower" : "",
				goldLimit ? "goldlimit" : ""
			],
			"rules": [
				canoutlane ? "canoutlane" : "",
				cantrecall ? "cantrecall" : ""
			]
		};

		axiosInstance.post("/api/matches/solo", data).then(() => {
			window.location.reload();
		}).catch((err) => {
			const errorMessage = err.response.data;
			setFeedbackMassage({ message: errorMessage.error, success: false, id: "createChallengeFeedback" });
		}).finally(() => {
			setLoading(false);
		});
	}

	return <form style={{ display: "flex", flexDirection: "column", width: "100%", marginTop: 10, marginBottom: 18, alignItems: "center", gap: 18 }} onSubmit={handleSubmit}>
		<Input type="text" value={title} onChange={(e) => setTitle(e.currentTarget.value)} name="title" required >
			Título
		</Input>
		<Input type="number" value={ticket} onChange={(e) => { setTicket(Number.parseFloat(e.currentTarget.value)); }} name="ticket" required >
			Valor (R$)
		</Input>
		<SubTitle>Mapa</SubTitle>
		<div style={{ display: "flex", width: "100%", justifyContent: "space-evenly", flexWrap: "wrap" }}>
			<RadioButton onChange={() => setMap("summonersrift")} defaultChecked id="summonersrift" name="map">Summoners`s Rift</RadioButton>
			<RadioButton onChange={() => setMap("howlingabyss")} id="howlingabyss" name="map" >Howling Abyss</RadioButton>
		</div>
		<SubTitle>Modo de seleção</SubTitle>
		<div style={{ display: "flex", width: "100%", justifyContent: "space-evenly", flexWrap: "wrap" }}>
			<RadioButton defaultChecked id="draft" name="pickmode" onChange={() => { setPickMode("draft"); }}>Escolha alternada</RadioButton>
			<RadioButton id="blind" name="pickmode" onChange={() => { setPickMode("blind"); }}>Às cegas</RadioButton>
		</div>
		<SubTitle>Condições de vitória</SubTitle>
		<div style={{ display: "flex", width: "100%", justifyContent: "space-evenly", flexWrap: "wrap", gap: 18 }}>
			<CheckBoxButton id="destroynexus" name="destroynexus" readOnly checked>Destruir nexus</CheckBoxButton>
			<CheckBoxButton checked={firstBlood} onChange={() => { setFirstBlood(!firstBlood); }} id="firstblood" name="firstblood">Primeiro Sangue</CheckBoxButton>
			<CheckBoxButton checked={firstTower} onChange={() => { setFirstTower(!firstTower); }} id="firsttower" name="firsttower">Primeira Torre</CheckBoxButton>
			<CheckBoxButton checked={goldLimit} onChange={() => { setGoldLimit(!goldLimit); }} id="goldlimit" name="goldlimit">Primeiro 100 de ouro</CheckBoxButton>
		</div>
		<SubTitle>Restrições</SubTitle>
		<div style={{ display: "flex", width: "100%", justifyContent: "space-evenly", flexWrap: "wrap", gap: 18 }}>
			<CheckBoxButton id="canoutlane" checked={canoutlane} onChange={() => { setCanOutLane(!canoutlane); }}>Não sair da lane</CheckBoxButton>
			<CheckBoxButton id="cantrecall" checked={cantrecall} onChange={() => { setCanTRecall(!cantrecall); }}>Não ir base</CheckBoxButton>
		</div>
		<Feedback id="createChallengeFeedback" />
		<Button type="submit" loading={loading} disabled={loading}>CRIAR</Button>
	</form>;
};

export default LolMatch;