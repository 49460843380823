import { faArrowDown, faArrowRightArrowLeft, faArrowUp, faEye, faEyeLowVision, faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useContext, useEffect } from "react";
import Template from "../../components/Template";
import colors from "../../components/UI/constants/colors";
import UserContext from "../../contexts/User";
import WalletVisionContext from "../../contexts/WalletVision";
import { ActionsContainer, Amount, AmountContainer, TransactionStyle, TransactionMessage, TransactionsContainer, TransactionValue, PerfilContainer, UserName } from "./styles";
import getRandomIcon from "../../functions/getRandomIcon";
import UserPic from "../../components/UI/UserPic/UserPic";
import { useNavigate } from "react-router-dom";
import IconButton from "../../components/UI/Buttons/IconButton";
import { Transaction } from "../../types/Transaction";
import axiosInstance from "../../services/axiosInstance";
import getFormatedDate from "../../functions/getFormatedDate";
import getFormatedTime from "../../functions/getFormatedTime";
import Modal from "../../components/UI/Modal/Modal";
import AddCurrency from "./ModalContents/AddCurrency/AddCurrency";
import { OpenModal } from "../../functions/modalFunctions";
import WithdrawCurrency from "./ModalContents/WithdrawCurrency/WithdrawCurrency";
import TransferCurrency from "./ModalContents/TransferCurrency/TransferCurrency";
import seo from "../../helpers/seo";
import ChangeCoinButton from "../../components/UI/Buttons/ChangeCoinButton/ChangeCoinButton";

const Wallet = () => {
	const Data = useContext(UserContext);
	const walletVision = useContext(WalletVisionContext);
	const [transactions, setTransactions] = useState<Transaction[]>([]);
	const navigate = useNavigate();
	const [modalPage, setModalPage] = useState<string>("");

	function NavigateTo(route: string) {
		window.hasPreviousUrl = true;
		navigate(route);
	}

	useEffect(() => {
		seo({
			title: "Minha Carteira"
		});
		axiosInstance.get("/api/finance/transactions"
		).then((res) => {
			if (res.data.data) {
				setTransactions(res.data.data);
			}
		});
	}, []);

	return <Template secondaryHeader text="Carteira" icon={faWallet}>
		<Modal id="WalletActions">
			<AddCurrency visible={modalPage === "Adicionar"} />
			<WithdrawCurrency visible={modalPage === "Retirar"} />
			<TransferCurrency visible={modalPage === "Transferir"} setTransactions={setTransactions} />
		</Modal>
		<PerfilContainer>
			<UserPic secondaryBackground onClick={() => NavigateTo("/minha-conta")}>
				{Data?.user?.user.profile_picture ? <img src={Data?.user?.user.profile_picture} /> : <FontAwesomeIcon icon={getRandomIcon()} size="4x" color={colors.WHITE} />}
			</UserPic>
			<UserName>{Data?.user?.user.nickname}</UserName>
		</PerfilContainer>
		<AmountContainer>
			<span style={{ color: colors.THIRD_GREEN, fontSize: 38 }}>R$</span>
			<Amount>{walletVision.visible ? Data?.user?.balance : "--------- "}</Amount>
			<ChangeCoinButton />
			<FontAwesomeIcon icon={walletVision.visible ? faEye : faEyeLowVision} color={colors.PRIMARY_GREEN} style={{ cursor: "pointer" }} size="2x" onClick={() => {
				walletVision.invertVisibility();
			}} />
		</AmountContainer>
		<ActionsContainer>
			<IconButton className="outlined" icon={faArrowDown} onClick={() => { setModalPage("Adicionar"); OpenModal("WalletActions"); }} color={colors.PRIMARY_GREEN}>
				ADICIONAR
			</IconButton>
			<IconButton className="outlined" icon={faArrowUp} onClick={() => { setModalPage("Retirar"); OpenModal("WalletActions"); }} color={colors.PRIMARY_GREEN}>
				SACAR
			</IconButton>
			<IconButton className="outlined" onClick={() => { setModalPage("Transferir"); OpenModal("WalletActions"); }} icon={faArrowRightArrowLeft} color={colors.PRIMARY_GREEN}>
				TRANSFERIR
			</IconButton>
		</ActionsContainer>
		<TransactionsContainer>
			<>{transactions.map((transaction: Transaction) => {
				return <TransactionStyle key={transaction.id}>
					<TransactionMessage color={transaction.receiver ? colors.PRIMARY_GREEN : colors.PRIMARY_RED}><>{transaction.origin} em {getFormatedDate(transaction.updated_at || "")} às {getFormatedTime(transaction.updated_at || "")}</></TransactionMessage>
					<TransactionValue color={transaction.receiver ? colors.PRIMARY_GREEN : colors.PRIMARY_RED}>
						R$
						<span style={{ marginLeft: 5 }}>{transaction.quantity}</span>
					</TransactionValue>
				</TransactionStyle>;
			})}</>
		</TransactionsContainer>
	</Template>;
};

export default Wallet;