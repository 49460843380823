import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import Button from "../../../../../components/UI/Buttons/Button";
import colors from "../../../../../components/UI/constants/colors";
import Feedback from "../../../../../components/UI/Feedback/Feedback";
import Input from "../../../../../components/UI/Inputs/Input/Input";
import { setFeedbackMassage } from "../../../../../functions/feedbackFunctions";
import axiosInstance from "../../../../../services/axiosInstance";
import { Hash, HashContainer, Instructions, InstructionsContainer } from "../styles";

type riotAuth = {
	authorization_string: string;
}

const LolContent = () => {
	const [riotToken, setToken] = React.useState<riotAuth>({ authorization_string: "" });
	const [page, setPage] = React.useState<number>(1);
	const [loading, setLoading] = React.useState(false);
	const [gameName, setGameName] = React.useState("");

	function handleCheck() {
		setLoading(true);
		axiosInstance.post("api/auth/riot/validate", { gamename: gameName }).then(() => { return; }).catch((err) => {
			if (err.response.data.error) {
				setFeedbackMassage({ message: err.response.data.error, success: false, id: "hashFeedback" });
			}
			else {
				setFeedbackMassage({ message: err.response.status, success: false, id: "hashFeedback" });
			}
		}).finally(() => {
			setLoading(false);
		});
	}

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);
		const fd = new FormData(e.currentTarget);
		axiosInstance.post<riotAuth>("api/auth/riot/request", fd).then((res) => {
			setToken(res.data); if (res.data.authorization_string) {
				setToken(res.data);
				setPage(2);
			}
		}).catch((err) => {
			setFeedbackMassage({ message: err.response.status, success: false, id: "reqFeedback" });
		}).finally(() => {
			setLoading(false);
		});

	}

	return <>
		<InstructionsContainer>
			<ol>
				<Instructions>Insira o seu nick do LoL no campo e envie uma requisição.</Instructions>
				<Instructions>Nas Configurações do League Of Legends desça até a aba Confirmação e insira o código abaixo.</Instructions>
			</ol>
		</InstructionsContainer>

		{page === 1 && <form style={{ display: "flex", gap: 18, flexDirection: "column", justifyContent: "center", alignItems: "center", maxWidth: "100%" }} onSubmit={handleSubmit}>
			<Input name="gamename" placeholder="Boneco_de_Treino" value={gameName} onChange={(e) => { setGameName(e ? e.currentTarget.value : ""); }}>
				Nome de usúario
			</Input>
			<Feedback id="reqFeedback" />
			<Button type="submit" loading={loading} disabled={loading}>
				REQUISITAR
			</Button>
		</form>}

		{page === 2 && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", maxWidth: "100%", flexDirection: "column", gap: 10 }}>
			<HashContainer>
				<Hash disabled value={riotToken.authorization_string} />
				<FontAwesomeIcon icon={faClipboard} size="lg" style={{ alignSelf: "center", margin: "auto" }} color={colors.PRIMARY_GREEN} onClick={() => {
					navigator.clipboard.writeText(riotToken.authorization_string);
				}} />
			</HashContainer>
			<Feedback id="hashFeedback" />
			<Button onClick={() => { handleCheck(); }} loading={loading} disabled={loading}>
				VERIFICAR
			</Button>
			<span style={{ textDecoration: "underline", color: colors.PRIMARY_GREEN, cursor: "pointer" }} onClick={() => { setPage(1); }}>Voltar</span>
		</div>}
	</>;
};

export default LolContent;