import { faGamepad, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import Template from "../../../components/Template";
import Carousel from "../../../components/UI/Carousel/Carousel";
import Challenge from "../../../components/UI/Challange/Challenge";
import colors from "../../../components/UI/constants/colors";
import Modal from "../../../components/UI/Modal/Modal";
import { OpenModal } from "../../../functions/modalFunctions";
import seo from "../../../helpers/seo";
// import TournamentEntrance from "../../../components/UI/TournamentEntrance/TournamentEntrance";
import axiosInstance from "../../../services/axiosInstance";
import { Challenges } from "../../../types/Challenges";
import CreateChallenge from "../createChallenge";
// import { Tournaments } from "../../../types/Tournaments";
import { CreateChallengeButton, MenuTitle } from "../styles";

const Game = () => {
	const { game } = useParams();
	const [challenges, setChallenges] = useState<Challenges[]>();
	// const [tounaments, setTounaments] = React.useState<Tournaments[]>();

	useEffect(() => {
		seo({
			title: "Jogar"
		});
		axiosInstance.get(`api/matches/solo?game=${game}&status[]=searching`).then((res) => {
			setChallenges(res.data.data);
		});

	}, []);

	// useEffect(() => {
	// 	axiosInstance.get("api/tournament").then((res) => {
	// 		setTounaments(res.data);
	// 	}).catch((err) => { console.log(err); });
	// }, []);

	return (
		<Template secondaryHeader icon={faGamepad} text="Desafios">
			{/* <MenuTitle>
				Torneios de {game}
			</MenuTitle>
			<Carousel height={350} >
				<>{tounaments && tounaments.map((tournament) => {
					return <CarouselItem key={tournament.id}>
						<TournamentEntrance data={tournament} />
					</CarouselItem>;
				})}</>
			</Carousel> */}
			<MenuTitle>
				Desafios Mais Recentes de {game}
			</MenuTitle>
			<Modal id="createChallenge">
				<CreateChallenge />
			</Modal>
			<Carousel height={350} >
				<SwiperSlide style={{ height: 295, width: 450 }}>
					<CreateChallengeButton onClick={() => OpenModal("createChallenge")}>
						<FontAwesomeIcon icon={faPlus} size="5x" color={colors.PRIMARY_GREEN} />
						<span>CRIE SEU PRÓPRIO DESAFIO</span>
					</CreateChallengeButton>
				</SwiperSlide>
				<>{challenges && challenges.map((challenge) => {
					return <SwiperSlide style={{ height: 295, width: 450 }} key={challenge.id}>
						<Challenge data={challenge} />
					</SwiperSlide>;
				})}</>
			</Carousel>
		</Template>
	);
};

{/* <Carousel height={350} >
			<SwiperSlide style={{ height: 295, width: 450 }}>
				<CreateChallengeButton onClick={() => OpenModal("createChallenge")}>
					<FontAwesomeIcon icon={faPlus} size="5x" color={colors.PRIMARY_GREEN} />
					<span>CRIE SEU PRÓPRIO DESAFIO</span>
				</CreateChallengeButton>
			</SwiperSlide>
			<>{challengesAll && returnRecent().map((challenge) => {
				return <SwiperSlide style={{ height: 295, width: 450 }} key={challenge.id}>
					<Challenge data={challenge} />
				</SwiperSlide>;
			})}</>
		</Carousel> */}

export default Game;