type dataType = {
    title?: string,
    description?: string
};

export default function(data : dataType) {
	document.title = `${data.title} | GGHub` || document.title;

	if(data.description)
		document.querySelector("meta[name='description']")?.setAttribute("content", data.description);

	return 0;
}