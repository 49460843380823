import { useState } from "react";
import ChooseGame from "./ChooseGame";
import Configs from "./Configs/Configs";

const CreateChallenge = () => {
	const [page, setPage] = useState("chooseGame");
	const [game, setGame] = useState("");

	switch (page) {
	case "chooseGame":
		return <ChooseGame visible={page === "chooseGame"} changePage={setPage} setGame={setGame}/>;
	case "configs":
		return <Configs visible={page === "configs"} changePage={setPage} game={game}/>;
	default:
		return <ChooseGame visible={page === "chooseGame"} changePage={setPage} setGame={setGame}/>;
	}
};

export default CreateChallenge;