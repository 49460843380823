import { faEdit, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect } from "react";
import * as React from "react";
import Template from "../../components/Template";
import colors from "../../components/UI/constants/colors";
import Input from "../../components/UI/Inputs/Input/Input";
import UserPic from "../../components/UI/UserPic/UserPic";
import UserContext from "../../contexts/User";
import { AccountContainer, AccountsContainer, CenterContainer, FormContainer, SectionTitle, UserPicInput, UserPicLabel } from "./styles";
import riotLogo from "../../assets/Icons/riotGames.png";
import battleNetLogo from "../../assets/Icons/battleNet.png";
import Modal from "../../components/UI/Modal/Modal";
import ConnectAccount from "./ModalContents/ConnectAccount/ConnectAccount";
import Button from "../../components/UI/Buttons/Button";
import getRandomIcon from "../../functions/getRandomIcon";
import axiosInstance, { returnPath } from "../../services/axiosInstance";
import Feedback from "../../components/UI/Feedback/Feedback";
import { setFeedbackMassage } from "../../functions/feedbackFunctions";
import { OpenModal } from "../../functions/modalFunctions";
import seo from "../../helpers/seo";

const Account = () => {
	const Data = useContext(UserContext);
	const [connectAccountType, setConnectAccountType] = React.useState("");
	const [loading, setLoading] = React.useState(false);
	const [sending, setSending] = React.useState(false);
	const [email, setEmail] = React.useState(Data?.user?.user.email || "");
	const [nickname, setNickname] = React.useState(Data?.user?.user.nickname || "");

	function handleImagePreview(e: React.ChangeEvent<HTMLInputElement>) {
		const file = e.target.files?.[0];
		if (file) {
			document.getElementById("avatar")?.setAttribute("src", URL.createObjectURL(file));
		}
	}

	useEffect(() => {
		seo({
			title: "Minha Conta"
		});

		if (Data?.user?.user) {
			setNickname(Data.user.user.nickname);
			setEmail(Data.user.user.email);
		}
	}, [Data]);

	function handleSubmitFirstForm(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setSending(true);
		const fd = new FormData(e.currentTarget);
		if (fd.get("email") === Data?.user?.user.email) {
			fd.set("email", "");
		}
		if (fd.get("nickname") === Data?.user?.user.nickname) {
			fd.set("nickname", "");
		}
		axiosInstance.post("/api/user/edit", fd).then(res => {
			if (res.status === 200) {
				location.reload();
			}
		}).catch(() => {
			setFeedbackMassage({
				success: false,
				message: "Erro ao atualizar o perfil",
				id: "changeProfile"
			});
		}).finally(() => {
			setSending(false);
		});
	}

	function handleSubmitSecondForm(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);
		const fd = new FormData(e.currentTarget);
		axiosInstance.post("/api/recovery-password", fd).then(res => {
			if (res.data) {
				setFeedbackMassage({ message: res.data.message, success: res.data.success, id: "changePassword" });
			}
		}).catch(err => {
			return setFeedbackMassage({ message: err.response.status, success: false, id: "changePassword" });
		}).finally(() => setLoading(false));
	}

	useEffect(() => {
		if(window.location.href.includes("code")) {
			setConnectAccountType("Battle Net");
			OpenModal("connectAccountsModal");
		}
	}, []);

	return <Template secondaryHeader icon={faUser} text="Conta">
		<Modal id="connectAccountsModal">
			<ConnectAccount type={connectAccountType} />
		</Modal>
		<FormContainer encType="multipart/form-data" onSubmit={handleSubmitFirstForm}>
			<UserPic>
				<UserPicLabel htmlFor="file-input">
					<FontAwesomeIcon icon={faEdit} size="2x" color={colors.PRIMARY_GREEN} />
				</UserPicLabel>
				{ Data?.user?.user.profile_picture ? <img src={`${Data?.user?.user.profile_picture}`} alt="avatar" id="avatar" /> : <FontAwesomeIcon icon={getRandomIcon()} size="5x" color={colors.WHITE} />}

				<UserPicInput type="file" accept="image/png,image/jpeg" name="profile_picture" id="file-input" multiple={false} onChange={e => handleImagePreview(e)} />
			</UserPic>
			<Input type="text" name="nickname" placeholder="Boneco de Treino" value={nickname} onChange={(e) => setNickname(e.currentTarget.value)}>
				Nickname
			</Input>
			<Input type="email" id="emailToChange" name="email" placeholder="bonecodetreino@riot.com" value={email} onChange={(e) => setEmail(e.currentTarget.value)}>
				Email
			</Input>
			<Button type="submit" loading={sending} disabled={sending}>
				SALVAR
			</Button>
			<Feedback id="changeProfile" />
		</FormContainer>
		<FormContainer onSubmit={handleSubmitSecondForm}>
			<SectionTitle>Trocar Senha</SectionTitle>
			<Input type="email" name="email" placeholder="bonecoDeTreino@teste.com">
				Email
			</Input>
			<Button type="submit" disabled={loading} loading={loading}>
				TROCAR
			</Button>
			<div style={{ maxWidth: 300 }}>
				<Feedback id="changePassword" />
			</div>
		</FormContainer>
		<CenterContainer>
			<SectionTitle>Conectar Contas</SectionTitle>
			<AccountsContainer>
				<AccountContainer>
					<div style={{ display: "flex", alignItems: "center" }}>
						<img src={riotLogo} alt="riot logo" width="70px" height="70px" />
						<span style={{ color: colors.WHITE, fontSize: 26, marginLeft: 5 }}>Riot Games</span>
					</div>
					{
						Data?.user?.riot_account ? <span style={{ color: colors.WHITE, fontSize: 26 }}>
							{Data.user.riot_account.gameName}
						</span> : <span style={{fontSize: 26, color: colors.PRIMARY_GREEN }}>EM BREVE</span>
					}
				</AccountContainer>
				<AccountContainer>
					<div style={{ display: "flex", alignItems: "center" }}>
						<img src={battleNetLogo} alt="Battle Net logo" width="70px" height="70px" />
						<span style={{ color: colors.WHITE, fontSize: 26, marginLeft: 5 }}>Battle Net</span>
					</div>
					{
						Data?.user?.battle_net_account ? <span style={{ color: colors.WHITE, fontSize: 26 }}>
							{Data.user.battle_net_account.battletag}
						</span> : <Button onClick={() => {
							window.location.href = `${returnPath()}/api/auth/battlenet/authorize`;
						}}>CONECTAR</Button>
					}
				</AccountContainer>
			</AccountsContainer>
		</CenterContainer>

	</Template>;
};

export default Account;