import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FooterContainer, FooterLink } from "./styles";
import DiscordIcon from "../../assets/Icons/discord.svg";
import InstragramIcon from "../../assets/Icons/instagram.svg";
import { faFileLines, faUserShield } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
	return <FooterContainer>
		<FooterLink target="_blank" href="https://discord.gg/DfRxZh4RBP">
			<img src={DiscordIcon} alt="Discord icon" height={28} width={29}/>
			<span>Discord</span>
		</FooterLink>
		<FooterLink href="/">
			<img src={InstragramIcon} alt="Instagram icon" height={28} width={29}/>
			<span>Instagram</span>
		</FooterLink>
		<FooterLink target="_blank" href="/termos-de-uso-e-privacidade">
			<FontAwesomeIcon icon={faFileLines} size="lg"/>
			<span>Termos de Uso</span>
		</FooterLink>
		<FooterLink target="_blank" href="/termos-de-uso-e-privacidade">
			<FontAwesomeIcon icon={faUserShield} size="lg"/>
			<span>Termos de Privacidade</span>
		</FooterLink>
	</FooterContainer>;
};

export default Footer;