import { useContext } from "react";
import { ChallangeData, ChallangeDiv, ChallengeContainer, ChallengeHeader, DataDiv, PrizeDiv, Topic, Value } from "./styles";
import LOLBackground from "../../../assets/Images/LeagueOfLegendsBackground.webp";
import LOLLogo2 from "../../../assets/Images/LeagueOfLegendsLogo2.webp";
import R6Background from "../../../assets/Images/RainbowSixSiegeBackground.webp";
import R6Logo from "../../../assets/Images/RainbowSixSiegeLogo.webp";
import TFTBackground from "../../../assets/Images/TFTBackground.webp";
import TFTLogo from "../../../assets/Images/TFTLogo2.webp";
import GenericGamesBackground from "../../../assets/Images/generic-game-banner.webp";
import WildRiftBackground from "../../../assets/Images/WildRiftBackground.webp";
import WildRiftLogo from "../../../assets/Icons/wildRift.svg";
import colors from "../constants/colors";
import OutlinedButton from "../Buttons/OutlinedButton";
import { Challenges } from "../../../types/Challenges";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../contexts/User";

type gamesType = {
	[key: string]: {
		background: string,
		logo: string
	}
}

const games: gamesType = {
	"lol": {
		background: LOLBackground,
		logo: LOLLogo2,
	},
	"wildrift": {
		background: WildRiftBackground,
		logo: WildRiftLogo
	},
	"r6": {
		background: R6Background,
		logo: R6Logo
	},
	"tft": {
		background: TFTBackground,
		logo: TFTLogo
	},
	"mtg": {
		background: "",
		logo: ""
	},
	"generic": {
		background: GenericGamesBackground,
		logo: ""
	}
};

function getMinutes(time: string) {
	const date = new Date();
	const start = new Date(time);
	let diffInMilliSeconds = Math.abs(date.getTime() - start.getTime()) / 1000;

	const days = Math.floor(diffInMilliSeconds / 86400);
	diffInMilliSeconds -= days * 86400;

	const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
	diffInMilliSeconds -= hours * 3600;

	const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
	diffInMilliSeconds -= minutes * 60;

	let difference = "";
	if (days > 0) {
		difference += (days === 1) ? `${days} dia ` : `${days} dias `;
	} else if (hours > 0) {
		difference += (hours === 0 || hours === 1) ? `${hours} hora ` : `${hours} horas `;
	} else if (minutes > 0) {
		difference += (minutes === 0 || hours === 1) ? `${minutes} minuto` : `${minutes} minutos`;
	} else {
		return "segundos";
	}

	return difference;
}


const Challenge = (props: { data: Challenges }) => {
	const { data } = props;
	const navigate = useNavigate();
	const userData = useContext(UserContext);

	function NavigateTo(route: string) {
		window.hasPreviousUrl = true;
		navigate(route);
	}

	function returnStatus() {
		const { playerA } = props.data;
		const { playerB } = props.data;
		const user = userData?.user?.user;

		if(playerA && playerB && user) {
			if (playerA.id !== user?.id && playerB?.id !== user?.id && data.status !== "ended") return "EM JOGO";
		}

		if(playerA.id === user?.id && !playerB) {
			return "MEU JOGO";
		}

		switch (props.data.status) {
		case "searching":
			return "DESAFIAR";
		case "ingame":
			return "VOTAÇÃO";
		case "validating":
			return "PREPARAÇÃO";
		case "ended":
			return "RESULTADOS";
		case "reported":
			return "RESULTADOS";
		default:
			return "";
		}
	}

	function returnUrl(url: string) {
		if (url.includes("http") || url.includes("https")) {
			return url;
		}
		else {
			if (process.env.REACT_APP_ENV === "production") {
				return `https://api.gghub.com.br${url}`;
			}
			else {
				return `https://staging-api-gghub.herokuapp.com${url}`;
			}
		}
	}

	function returnStatusText() {
		switch (data.status) {
		case "searching":
			return "Procurando...";
		case "ingame":
			return "Lutando";
		case "validating":
			return "Validando...";
		case "ended":
			return "Finalizado";
		case "reported":
			return "Jogo reportado";
		default:
			return;
		}
	}

	return <ChallengeContainer background={games[data.game.toString()].background} >
		<ChallengeHeader>
			<div style={{ display: "flex" }}>
				<img src={returnUrl(data.playerA.profile_picture)} alt="imagem do usuario" className="user-picture" />
				<div className="user-data">
					<span className="user-name">{data.playerA.nickname}</span>
					<span className="challange-name">{data.title}</span>
				</div>
			</div>
			{
				games[data.game].logo !== "" ? 
					<img src={games[data.game].logo} className="game-logo" style={{width: 60, height: 63, marginRight: 20}} alt="game logo"/> : <></>
			}
		</ChallengeHeader>
		<ChallangeDiv>
			<ChallangeData>
				<DataDiv>
					<Topic>Custo: <Value><span style={{color: colors.THIRD_GREEN, fontWeight: "bold"}}>R$ </span>{data.ticket}</Value></Topic>
					<Topic>Status: <Value>{returnStatusText()}</Value></Topic>
					<Topic>Iniciado: <Value>Há {getMinutes(data.created_at)}</Value></Topic>
					<OutlinedButton onClick={() => {
						NavigateTo("/desafio/" + data.id);
					}}>{returnStatus()}</OutlinedButton>
				</DataDiv>
				<PrizeDiv>
					<span className="prize">PRÊMIO</span>
					<Value><span style={{color: colors.THIRD_GREEN, fontWeight: "bold"}}>R$ </span>{data.prize}</Value>
				</PrizeDiv>
			</ChallangeData>
		</ChallangeDiv>
	</ChallengeContainer >;
};

export default Challenge;