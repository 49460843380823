import styled from "styled-components";
import colors from "../constants/colors";

export const RadioStyled = styled.div`
  width: fit-content;

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: ${colors.WHITE};
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.container.disabled {
  cursor: not-allowed;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 3px solid ${colors.PRIMARY_GRAY};
  border-radius: 50%;
}

.checkmark.disabled {
  border: 1px solid ${colors.PRIMARY_GRAY};	
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  border: 6px solid ${colors.PRIMARY_GREEN};
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
`;