import { ChatBlackSpace, ChatContainer, ChatDiv } from "./styles";
import List from "./Pages/List/List";
import Conversation from "./Pages/Conversation/Conversation";
import { CloseChat } from "../../../functions/chatFunctions";
import { useState } from "react";
import { Conversations } from "../../../types/Conversations";
import { Messages } from "../../../types/Messages";

export type ConversationType = {
	conversation: Conversations,
	name: string,
	chatPicture: JSX.Element
}

export default function Chat() {
	const [page, setPage] = useState<"list" | "conversation">("list");
	const [conversation, setConversation] = useState<ConversationType | null>(null);
	const [messages, setMessages] = useState<Messages | null>(null);
	const [nextPage, setNextPage] = useState<string | null>(null);

	return (
		<ChatDiv id="chatDiv">
			<ChatBlackSpace onClick={() => {
				CloseChat();
			}} />
			<ChatContainer>
				<div style={{ display: page === "list" ? "block" : "none", width: "100%", height: "100%", paddingTop: 15 }}>
					<List setPage={setPage} setConversation={setConversation} setMessages={setMessages} setNextPage={setNextPage}/>
				</div>
				<div style={{ display: page === "conversation" ? "flex" : "none", width: "100%", height: "100%", justifyContent: "space-between", flexDirection: "column" }}>
					{
						conversation ?
							<Conversation conversation={conversation} setPage={setPage} messages={messages} nextPage={nextPage} />
							: null
					}
				</div>
			</ChatContainer>
		</ChatDiv>
	);
}