import styled from "styled-components";
import colors from "../../components/UI/constants/colors";

export const Banner = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 300px;
	width: 100%;

	&::before {
		content: "";
		position: absolute;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		opacity: 0.2;
	  	background-image: url(${(props: {background: string}) => props.background});
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	& span {
		font-size: 40px;
		font-family: "Inter-Bold", sans-serif;
		color: ${colors.WHITE};
	}
`;

export const Users = styled.div`
    display: flex;
	flex-direction: row;
    justify-content: center;
    gap: 18px;
    align-items: center;
    width: 100%;
    padding: 5px 18px 5px 18px;

	@media (max-width: 760px){
		flex-direction: column;
	}
`;

export const Divisor = styled.span`
    font-size: 36px;
    color: ${colors.PRIMARY_GREEN};
`;

export const Details = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 18px;
	gap: 10px;
	width: 100%;
	background-color: ${colors.PRIMARY_GRAY};
`;

export const Topic = styled.span`
	font-size: 24px;
	font-family: "Inter-Bold", sans-serif;
	color: ${colors.WHITE};
`;

export const Value = styled.span`
	font-size: 24px;
	font-family: "Inter", sans-serif;
	margin-left: 5px;
	color: ${colors.WHITE};
`;

export const Title = styled.span`
	font-size: 32px;
	color: ${colors.WHITE};
	margin-top: 18px;
	margin-bottom: 18px;
	margin-left: auto;
	margin-right: auto;
`;

export const RulesContainer = styled.div`
	background-color: ${colors.PRIMARY_GRAY};
	padding: 18px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: fit-content;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	border-radius: 10px;

	& p, li {
		font-size: 22px;
		color: ${colors.WHITE};
	}
`;

export const VoteContainer = styled.div`
	display: flex;
	margin-top: 18px;
	margin-left: auto;
	margin-right: auto;
	max-width: 600px;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 18px;

	@media (max-width: 760px){
		flex-direction: column;
		}
`;

export const ChatBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  height: 80%;
  width: 92%;
  max-width: 380px;
  max-height: 500px;
  position: relative;
  gap: 10px;
  border-radius: 10px;
  margin: auto;
  background-color: ${colors.PRIMARY_GRAY};

  @media (max-width: 768px) {
    width: 100%;
  }
`;