import { faChevronLeft, faCircleNotch, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from "react";
import ChatInput from "../../../components/Menu/Chat/ChatInput/ChatInput";
import IconButton from "../../../components/UI/Buttons/IconButton";
import colors from "../../../components/UI/constants/colors";
import UserContext from "../../../contexts/User";
import axiosInstance from "../../../services/axiosInstance";
import { Message } from "../../../types/Message";
import { Messages } from "../../../types/Messages";
import { User } from "../../../types/User";
import { ChatContainer, DataLabel, MessageContainer, MessagesStyle, MessageText } from "./styles";

function Conversation(props: { messages: Messages | null, nextPage: string | null, conversationId?: number, members?: User["user"][] }) {
	const [messages, setMessages] = React.useState<Messages | null>(props.messages);
	const [loading, setLoading] = React.useState(false);
	const UserData = React.useContext(UserContext);
	const messagesContainer = useRef<HTMLDivElement>(null);
	const [canUpdate, setCanUpdate] = React.useState<boolean>(true);
	const [canScrollDown, setCanScrollDown] = React.useState<boolean>(true);
	const [nextPage, setNextPage] = React.useState<string | null>(props.nextPage);
	const [links, setLinks] = React.useState<string[]>([]);

	useEffect(() => {
		setMessages(props.messages);
	}, [props.messages]);

	useEffect(() => {
		setNextPage(props.nextPage);
	}, [props.nextPage]);


	function sendMessage(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);
		const formData = new FormData(e.currentTarget);
		axiosInstance.post(`/api/chat/${props.conversationId}/messages/send`, formData).then(() => {
			axiosInstance.get(`api/chat/${props.conversationId}/messages`).then((res) => {
				setLinks([]);
				setMessages(res.data.messages);
			});
		}).finally(() => {
			setLoading(false);
			const textInput = document.getElementById("message") as HTMLInputElement;
			textInput.value = "";
			messagesContainer?.current?.scrollTo(0, messagesContainer.current.scrollHeight);
		});
	}

	useEffect(() => {
		if (messagesContainer.current && canScrollDown) {
			messagesContainer.current.scrollTo(0, messagesContainer.current.scrollHeight);
			setCanScrollDown(false);
		}
	}, [messagesContainer && messagesContainer.current?.scrollHeight]);

	function updateMessages() {
		if (messagesContainer.current && canUpdate) {
			const container = messagesContainer.current;
			const maxScroll = container.clientHeight - container.scrollHeight;
			const scrollBottom = maxScroll - container.scrollTop;
			if (scrollBottom === 0) {
				getMessages(nextPage);
				setCanUpdate(false);
				setTimeout(() => {
					setCanUpdate(true);
				}, 2000);
			}
		}
	}

	function handleMessages() {
		if (messages) {
			if (messages.data.length === 0) return;

			if (!messages.data.length) {
				return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop: 25, height: "80%" }}><FontAwesomeIcon icon={faCircleNotch} size="2x" color={colors.WHITE} className="fa-spin" /></div>;
			}

			const messages_list = messages.data;
			return <MessagesStyle onScroll={() => updateMessages()} ref={messagesContainer}>{messages_list.map(data => {
				const messageDate = new Date(data.created_at);
				const isMine = data.from === UserData?.user?.user.id;
				const members = props.members;
				const sender = members?.filter((item) => {
					return item.id == data.from;
				})[0];
				
				return <MessageContainer key={data.id} isMine={isMine}>
					<div>
						{ isMine?null:
							<span style={{ fontSize: 13, display: "block", color: "#B3FF66", fontWeight: "bold" }}>{ sender?.nickname }</span>
						}
						<MessageText>{data.message}</MessageText>
					</div>
					<DataLabel isMine={isMine}>{messageDate.toLocaleDateString().substring(0, 5)} - {messageDate.toLocaleTimeString().substring(0, 5)}</DataLabel>
				</MessageContainer>;
			})}</MessagesStyle>;
		}
	}

	function getMessages(link?: string | null) {
		if (link) {
			if (links.includes(link)) return;
			setLinks(prevState => {
				return [...prevState, link];
			});
			return axiosInstance.get(link).then((res) => {
				const newMessages: Message[] = res.data.messages.data;
				setMessages(prevState => {
					if (prevState) {
						const newMessagesUnion = [...prevState.data, ...newMessages];
						return {
							...prevState,
							data: newMessagesUnion,
							next_page_url: res.data.messages.next_page_url
						};
					}
					return null;
				});
			});
		}
		axiosInstance.get(`api/chat/${props.conversationId}/messages`).then((res) => {
			setMessages(res.data.messages);
		}).finally(() => {
			messagesContainer?.current?.scrollTo(0, messagesContainer.current.scrollHeight);
		});
	}

	useEffect(() => {
		getMessages();
	}, [props.conversationId]);

	return <React.Fragment>
		<ChatContainer>
			<>{handleMessages()}</>
		</ChatContainer>
		<form autoComplete="off" onSubmit={(e) => sendMessage(e)} style={{ width: "95%", marginBottom: 25, marginLeft: "auto", marginRight: "auto", display: "flex", alignItems: "center" }}>
			<ChatInput name="message">
				Mensagem
			</ChatInput>
			<IconButton loading={loading} disabled={loading} type="submit" onClick={() => { return; }} icon={faPlay} color={colors.PRIMARY_GREEN} nobackground styles={{ width: 40, justifyContent: "center" }} />
		</form>
	</React.Fragment>;
}

export default Conversation;