import styled from "styled-components";
import colors from "../../../../components/UI/constants/colors";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    & img {
        width: 150px;
    }
`;

export const ContainerTitle = styled.span`
    font-size: 32px;
    color: ${colors.WHITE};

`;

export const ContainerAccountTitle = styled.span`
    font-size: 28px;
    color: ${colors.WHITE};
`;

export const InstructionsContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    background-color: ${colors.PRIMARY_GRAY};
    padding: 18px;
    border-radius: 10px;
    border: 1px solid ${colors.PRIMARY_GREEN};
    margin-bottom: 18px;
`;



export const Number = styled.span`
    color: ${colors.PRIMARY_GREEN};
    margin-right: 10px;
`;

export const Instructions = styled.li`
    color: ${colors.WHITE};
    font-size: 22px;
    margin-left: 20px;

    &::marker {
        color: ${colors.PRIMARY_GREEN};
    }
`;

export const HashContainer = styled.div`
    width: 90%;
    padding: 5px;
    border: 1px solid ${colors.PRIMARY_GREEN};
    border-radius: 10px;
    background-color: ${colors.PRIMARY_GRAY};
    position: relative;
    display: flex;
    flex-direction: row;
`;

export const Hash = styled.input`
    font-size: 22px;
    border: none;
    width: 90%;
    color: ${colors.WHITE};
    background-color: ${colors.PRIMARY_GRAY};
`;

