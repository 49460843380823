import * as React from "react";
import Button from "../../../../components/UI/Buttons/Button";
import colors from "../../../../components/UI/constants/colors";
import Feedback from "../../../../components/UI/Feedback/Feedback";
import Input from "../../../../components/UI/Inputs/Input/Input";
import Textarea from "../../../../components/UI/Inputs/Textarea/textarea";
import { setFeedbackMassage } from "../../../../functions/feedbackFunctions";
import axiosInstance from "../../../../services/axiosInstance";
import { Container, Form } from "./styles";

const WithdrawCurrency = (props: { visible: boolean }) => {
	const [loading, setLoading] = React.useState(false);

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);
		const fd = new FormData(e.currentTarget);
		axiosInstance.post("/api/withdrawal", fd).then(res => {
			console.log(res);
			if(res.status === 200) {
				setFeedbackMassage({
					message: "Requisição de saque realizada com sucesso",
					success: true,
					id: "withdrawFeedback"
				});
				setLoading(false);
			}
		}).catch(err => {
			setFeedbackMassage({
				message: err.response.data.message ? err.response.data.message : "Erro ao realizar a requisição de saque", 
				success: false,
				id: "withdrawFeedback"
			});
		}).finally(() => {
			setLoading(false);
		});
	}

	return <Container isOpen={props.visible}>
		<span style={{ fontSize: 32, color: colors.WHITE }}>DADOS BANCÁRIOS</span>
		<Form onSubmit={e => handleSubmit(e)}>
			<Input name="quantity" type="number" placeholder="100.00">
				Quantidade
			</Input>
			<Textarea name="details">
				<span>Dados bancários<br /><br />PIX CPF: 123.456.789-00</span>
			</Textarea>
			<Input name="password" type="password">
				Senha
			</Input>
			<span style={{textAlign: "start", fontStyle: "italic", color: colors.PRIMARY_GREEN, maxWidth: 315}}>*Os saques são transferidos  manualmente para a conta com os dados informado no campo PIX. Essa validação de segurança pode demorar até 2 (dois) dias.</span>
			<Feedback id="withdrawFeedback" />
			<Button type="submit" loading={loading} disabled={loading}>
				SOLICITAR
			</Button>
		</Form>
	</Container>;

};

export default WithdrawCurrency;