import styled from "styled-components";
import banner from "../../assets/Images/WarzoneBackground2.webp";
import colors from "../../components/UI/constants/colors";

export const Banner = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 300px;
	width: 100%;

	&::before {
		content: "";
		position: absolute;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		opacity: 0.2;
	  	background-image: url(${banner});
		background-size: cover;
		background-position: 50% -100px;
		background-repeat: no-repeat;

		@media (max-width: 768px) {
			background-position: center;
		}
	}

	& span {
		font-size: 40px;
		font-family: "Inter-Bold", sans-serif;
		color: ${colors.WHITE};
	}
`;

export const Details = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 18px;
	gap: 10px;
	width: 100%;
	background-color: ${colors.PRIMARY_GRAY};
`;

export const Topic = styled.span`
	font-size: 24px;
	font-family: "Inter-Bold", sans-serif;
	color: ${colors.WHITE};
`;

export const Value = styled.span`
	font-size: 24px;
	font-family: "Inter", sans-serif;
	color: ${colors.WHITE};
`;

export const PlayersContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 100%;
	text-align: center;
	width: fit-content;
	gap: 18px;
	margin: 18px;

	@media (max-width: 768px) {
		margin-left: auto;
		margin-right: auto;
	}
`;

export const PlayContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 100%;
	width: 600px;
	height: 500px;
	margin: 18px;
	gap: 18px;

	@media (max-width: 768px) {
		margin-left: auto;
		margin-right: auto;
	}
`;

export const Title = styled.span`
	font-size: 35px;
	font-family: "Inter-Bold", sans-serif;
	color: ${colors.WHITE};
`;

export const Video = styled.iframe`
	width: 100%;
	height: 100%;
	border-radius: 5px;
	border: 1px solid ${colors.PRIMARY_GREEN};
`;

export const Controllers = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

export const Scoreboard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 100%;
	width: 500px;
	margin: 18px;
	gap: 18px;

	@media (max-width: 768px) {
		margin-left: auto;
		margin-right: auto;
	}
`;

export const PlayerPoints = styled.div`
	background-color: ${colors.PRIMARY_GRAY}; 
	padding: 18px; 
	display: flex;
	flex-direction: column;
	gap: 15px;
	border-radius: 10px;
	max-height: 500px; 
	width: 550px;
	max-width: 100%;
	overflow-y: scroll;

	@media (max-width: 768px) {
		max-height: 500px;
	}
`;

export const Winner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 250px;
	width: fit-content;
	text-overflow: ellipsis;
	overflow: hidden;

	& img {
		width: 75px;
		height: 75px;
		border-radius: 50%;
		border: 1px solid ${colors.PRIMARY_GREEN};
	}
`;
