import { Container, ContainerAccountTitle, ContainerTitle } from "./styles";
import RiotLogo from "../../../../assets/Icons/riotGames.png";
import battleNetLogo from "../../../../assets/Icons/battleNet.png";
import LolContent from "./LolContent";
import BattleNetContent from "./BattleNetContent";


const ConnectAccount = (props: { type: string }) => {


	const dataTypes = [
		{
			name: "Riot Games",
			icon: RiotLogo,
		},
		{
			name: "Battle Net",
			icon: battleNetLogo,
		}
	];

	

	const game = dataTypes.find((type) => { return type.name === props.type; });

	

	return <Container>
		<ContainerTitle>Conectar Conta</ContainerTitle>
		<img src={game?.icon} alt={game?.name} width="100%" height="100%" />
		<ContainerAccountTitle>{game?.name}</ContainerAccountTitle>
		{props.type === "Riot Games" && <LolContent/>}
		{props.type === "Battle Net" && <BattleNetContent/>}
	</Container>;
};

export default ConnectAccount;