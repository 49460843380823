import styled from "styled-components";
import colors from "../UI/constants/colors";

export const MenuContainer = styled.div`
    display: none; 
    
    &.show {
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      z-index: 99; 
      left: 0;
      top: 0;
      width: 100%; 
      height: 100%;
      background-color: rgb(0,0,0); 
      background-color: rgba(0,0,0,0.4);
    }
`;

export const MenuContent = styled.div`
    height: 100%;
    width: 620px;
    display: flex;
    flex-direction: column;
    background-color: ${colors.PRIMARY_BLACK};

    @media (max-width: 768px) {
      width: 100%;
    }
`;

export const MenuBlackSpace = styled.div`
    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
      display: none;
    }
`;

export const MenuHeader = styled.div`
  height: 65px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 18px;

  &.two-icons {
    grid-template-columns: 1fr 1fr;
  }

  & .back-button {
    align-self: center;
    justify-self: start;
  }
  

  & .close-menu {
    display: none;

    @media (max-width: 768px) {
      display: block;
      align-self: center;
      justify-self: end;
    }
  }
`;

export const MenuActions = styled.div`
  height: 100%;
  width: 100%;
  gap: 25px;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 18px;
  overflow-y: auto;
`;

export const Form = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  gap: 25px;
`;