import { createContext, useState } from "react";

type WalletVisionContextProps = {
	visible: boolean | undefined;
	invertVisibility: () => void;
}

const WalletVisionContext = createContext<WalletVisionContextProps>({
	visible: (localStorage.getItem("gghub@hideWallet") === "true"), invertVisibility: () => {
		return;
	}
});

const WalletVisionContextProvider = ({ children }: { children: JSX.Element }) => {
	const [visible, setVisible] = useState<boolean>(localStorage.getItem("gghub@hideWallet") === "true");

	function invertVisibility() {
		setVisible(!visible);
		localStorage.setItem("gghub@hideWallet", `${!visible}`);
	}

	return <WalletVisionContext.Provider value={{ visible, invertVisibility }}>{children}</WalletVisionContext.Provider>;
};

export { WalletVisionContextProvider };
export default WalletVisionContext;