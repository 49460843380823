import colors from "../components/UI/constants/colors";

export function setFeedbackMassage(props: { message: string, id: string, success: boolean }) {
	const ErrorsList = {
		500: "Erro interno do servidor",
		400: "Erro de validação",
		401: "Não autorizado",
		403: "Proibido",
		404: "Não encontrado",
		405: "Método não permitido",
		406: "Não aceitável",
		407: "Autenticação de proxy necessária",
		408: "Tempo limite excedido",
		409: "Conflito",
		410: "Expirado",
		411: "Dados insuficientes",
		412: "Pré-condição falhou",
		413: "Entidade muito grande",
		414: "Endereço URI muito longo",
		415: "Tipo de mídia não suportado",
		416: "Faixa não satisfatória",
		417: "Falha de espera",
		418: "Não é possível"
	};

	const feedback = document.getElementById(props.id);
	if (feedback) {
		if (Number.isInteger(props.message)) {
			Object.keys(ErrorsList).forEach(key => {
				if (props.message == key) {
					feedback.innerHTML = Object.values(ErrorsList)[Object.keys(ErrorsList).indexOf(key)];
				}
			});
		} else {
			feedback.innerHTML = props.message;
		}
		if (props.success) {
			feedback.style.color = colors.PRIMARY_GREEN;
		} else {
			feedback.style.color = colors.PRIMARY_RED;
		}
		setTimeout(() => {
			feedback.innerHTML = "";
		}, 4000);
	}
}