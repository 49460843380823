import { useContext } from "react";
import UserContext from "../../../contexts/User";
import { UserPicDiv } from "./styles";

const UserPic = ({ children, onClick, secondaryBackground }: { children: JSX.Element[] | JSX.Element, onClick?: () => void, secondaryBackground?: boolean }) => {
	const User = useContext(UserContext);
	return <UserPicDiv onClick={onClick} className={`${User?.user !== undefined ? "clickable" : ""} ${secondaryBackground ? "backgroundColored" : ""}`}>
		{children}
	</UserPicDiv>;
};

export default UserPic;