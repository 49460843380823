import styled from "styled-components";
import colors from "../constants/colors";

export const TournamentEntranceContainer = styled.div`
    min-width: 437.39px;
    max-width: 553px;
    height: 306.39px;

    &::before {
      content: "";
      border-radius: 10px;
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.2;
      background-image: url(${(props: { background?: string }) => props.background});
    }
`;

export const TournamentEntranceHeader = styled.div`
    display: flex;
    height: 102px;
    justify-content: space-between;
    position: relative;
    width: 100%;
    align-items: center;
    margin-bottom: 16px;
    padding: 0 16px;
    flex-shrink: 0;
    
    & span {
        font-size: 30px;
        color: ${colors.WHITE};
        max-width: 453px;
        overflow: hidden;
        font-family: "Inter-Bold", sans-serif;
        text-overflow: ellipsis;
    }

    & img {
        width: 100px;
        display: block;
    }
    
`;

export const TournamentEntranceDataDiv = styled.div`
    position: relative;
    background-color: ${colors.PRIMARY_GRAY};
    border-radius: 10px 10px 0 0;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

export const TournamentEntranceData = styled.div`
    display: flex;
    width: 500px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

export const DataDiv = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const PrizeDiv = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
`;

export const Topic = styled.span`
    font-size: 22px;
    margin-bottom: 10px;
    margin-right: 10px;
    color: ${colors.WHITE};
    font-family: "Inter-Bold", sans-serif;
`;

export const Value = styled.span`
    font-size: 22px;
    color: ${colors.WHITE};
    font-family: "Inter", sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
`;