import { faCheck, faFistRaised } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Button from "../../../components/UI/Buttons/Button";
import colors from "../../../components/UI/constants/colors";
import Modal from "../../../components/UI/Modal/Modal";
import UserPic from "../../../components/UI/UserPic/UserPic";
import getRandomIcon from "../../../functions/getRandomIcon";
import { OpenModal } from "../../../functions/modalFunctions";
import axiosInstance from "../../../services/axiosInstance";
import { Player } from "../../../types/Player";
import { Container, FlexibleSpace } from "./styles";

const ChallengePlayer = (props: { player: Player | null | undefined, winner: number | undefined | null, hasToBeReady?: boolean, status?: string, challengeId?: number}) => {
	const [loading, setLoading] = useState(false);

	function isWinner() {
		switch (props.winner) {
		case 1:
			return colors.PRIMARY_GREEN;
		case 0:
			return colors.PRIMARY_RED;
		default:
			return colors.WHITE;
		}
	}

	function handleReady() {
		setLoading(true);
		axiosInstance.post(`/api/matches/solo/${props.challengeId}/ready`).then(res => {
			if (res.status === 200) {
				window.location.reload();
			}
		}).finally(() => setLoading(false));
	}

	function isReady() {
		if(props.winner) {
			return <FlexibleSpace><span style={{marginRight: 5, fontSize: 28}}>PRONTO</span><FontAwesomeIcon icon={faCheck} size="3x" color={colors.PRIMARY_GREEN}/></FlexibleSpace>;
		}
		if (props.hasToBeReady) {
			return <div style={{display: "flex", width: "100%", justifyContent: "center"}}><Button onClick={() => warnUser()} loading={loading} disabled={loading}>PRONTO</Button></div>;
		} else {
			if(props.status !== "searching") { 
				return <FlexibleSpace/>;
			}
		}}

	function warnUser() {
		OpenModal("prepareModal");
	}

	return <Container>
		<Modal id="prepareModal">
			<div style={{ display: "flex", flexDirection: "column", gap: 20, alignItems: "center" }}>
				<FontAwesomeIcon icon={faFistRaised} size="4x" color={colors.PRIMARY_GREEN} />
				<span style={{
					fontSize: 32,
					color: colors.WHITE,
					fontFamily: "Inter-Bold"
				}}>Você está pronto para jogar?</span>
				<span style={{
					fontSize: 22,
					maxWidth: 400,
					color: colors.WHITE,
					textAlign: "center",
				}}>Apenas prossiga se você estiver pronto para jogar agora.</span>
				<span style={{
					fontSize: 22,
					color: colors.PRIMARY_GREEN,
					textAlign: "center",
					fontStyle: "italic"
				}}>*Operação irreversível</span>
				<Button disabled={loading} onClick={() => handleReady()}>PRONTO</Button>
			</div>
		</Modal>
		{props.player ?
			<div style={{display: "flex", flexDirection: "column", gap: 18 }}>
				<div style={{ display: "flex", gap: 5, alignItems: "center", maxWidth: "100%", textOverflow: "ellipsis", overflow: "hidden", flexWrap: "wrap", justifyContent: "center" }}>
					<img src={props.player.profile_picture} alt={props.player.nickname} />
					<span style={{ color: isWinner() }}>{props.player?.nickname}</span>
				</div>
				{props.status === "validating" && isReady()}
			</div>
			: <div style={{ display: "flex", gap: 5, alignItems: "center", maxWidth: "100%", textOverflow: "ellipsis", overflow: "hidden", flexWrap: "wrap", justifyContent: "center" }}>
				<UserPic>
					<FontAwesomeIcon icon={getRandomIcon()} color={colors.SECONDARY_GRAY} size="4x" />
				</UserPic>
				<span>PROCURANDO...</span>
			</div>
		}

	</Container>;
};

export default ChallengePlayer;