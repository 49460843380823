import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../../components/UI/Buttons/Button";
import colors from "../../../components/UI/constants/colors";
import Modal from "../../../components/UI/Modal/Modal";

type voteWarningProps = {
	loading: boolean;
	handleVote: () => void;
	returnVoteText: () => JSX.Element | undefined;
}

const VoteWarning = (props: voteWarningProps) => {
	return <Modal id="voteWarning">
		<div style={{ display: "flex", flexDirection: "column", gap: 20, alignItems: "center" }}>
			<FontAwesomeIcon icon={faCheckCircle} size="4x" color={colors.PRIMARY_GREEN} />
			<span style={{
				fontSize: 32,
				color: colors.WHITE,
				fontFamily: "Inter-Bold"
			}}>Confirme seu voto</span>
			<span style={{
				fontSize: 22,
				color: colors.WHITE,
			}}>{props.returnVoteText()}</span>
			<span style={{
				fontSize: 22,
				color: colors.PRIMARY_GREEN,
				fontStyle: "italic"
			}}>*Operação irreversível</span>
			<Button disabled={props.loading} onClick={() => props.handleVote()}>VOTAR</Button>
		</div>
	</Modal>;
};

export default VoteWarning;