import styled from "styled-components";
import colors from "../../../../../../components/UI/constants/colors";

export const Container = styled.div`
    display: ${(props: {isOpen: boolean}) => (props.isOpen ? "flex" : "none")};
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const PixQRCode = styled.img`
    width: 250px;
    height: 250px;
    max-width: 100%;
    border-radius: 10px;
    border: 1px solid ${colors.PRIMARY_GREEN};
`;

export const HashContainer = styled.div`
    width: 90%;
    margin-top: 20px;
    padding: 5px;
    border: 1px solid ${colors.PRIMARY_GREEN};
    border-radius: 10px;
    background-color: ${colors.PRIMARY_GRAY};
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
`;

export const Hash = styled.input`
    font-size: 22px;
    border: none;
    width: 90%;
    color: ${colors.WHITE};
    background-color: ${colors.PRIMARY_GRAY};
`;
