import { Dispatch, SetStateAction } from "react";
import { Container, MethodContainer } from "./styles";
import PixLogo from "../../../../../../assets/Icons/pix-106.svg";
import colors from "../../../../../../components/UI/constants/colors";

const PaymentTypes = (props: { visible: boolean, changePage: Dispatch<SetStateAction<string>> }) => {
	return <Container isShowing={props.visible}>
		<MethodContainer onClick={() => props.changePage("pixPage")}><img src={PixLogo} /><span>PIX</span></MethodContainer>
		<div style={{width: "100%", display: "flex", justifyContent: "center"}}>
			<span style={{color: colors.PRIMARY_GREEN, textDecoration: "underline", fontSize: 18, cursor: "pointer"}} onClick={() => {
				props.changePage("packages");
			}}>voltar</span>
		</div>
	</Container>;
};

export default PaymentTypes;