import { DataDiv, PrizeDiv, Topic, TournamentEntranceContainer, TournamentEntranceData, TournamentEntranceDataDiv, TournamentEntranceHeader, Value } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import colors from "../constants/colors";
import OutlinedButton from "../Buttons/OutlinedButton";
import { useNavigate } from "react-router-dom";
import getFormatedDate from "../../../functions/getFormatedDate";
import getFormatedTime from "../../../functions/getFormatedTime";
import { WarzoneTournaments } from "../../../types/WarzoneTournaments";
import WarzoneBackground from "../../../assets/Images/WarzoneBackground.webp";
import WarzoneLogo from "../../../assets/Images/WarzoneLogo.webp";

const TournamentEntrance = (props: { data: WarzoneTournaments }) => {
	const { data } = props;
	const navigate = useNavigate();

	function NavigateTo(path: string) {
		window.hasPreviousUrl = true;
		navigate(path);
	}

	function returnPrize() {
		return data.prize_distribution.map((prize, index) => {
			const allPrize = data.slots * data.ticket;
			return <Topic key={index}>{index + 1}º <Value>{prize * allPrize / 100}</Value><FontAwesomeIcon icon={faCoins} style={{ position: "relative", top: 0, color: colors.THIRD_GREEN, marginLeft: 5 }} size="1x" /></Topic>;
		});
	}

	return <TournamentEntranceContainer background={WarzoneBackground}>
		<TournamentEntranceHeader>
			<div style={{ display: "flex", flexDirection: "column" }}>
				<span>COD Warzone</span>
				<span>{data.title}</span>
			</div>
			<div style={{ width: 80, height: 80, backgroundImage: `url(${WarzoneLogo})`, backgroundRepeat: "no-repeat", backgroundSize: "contain" }} />
		</TournamentEntranceHeader>
		<TournamentEntranceDataDiv>
			<TournamentEntranceData>
				<DataDiv>
					<Topic>Entrada: <Value>{data.ticket}<FontAwesomeIcon icon={faCoins} style={{ position: "relative", top: 0, color: colors.THIRD_GREEN, marginLeft: 5 }} size="1x" /></Value></Topic>
					<Topic>Modo: <Value>{data.gamemode}</Value></Topic>
					<Topic>Início: <Value>{getFormatedDate(data.start_at) + " " + getFormatedTime(data.start_at)}</Value></Topic>
					<Topic>Término: <Value>{getFormatedDate(data.end_at) + " " + getFormatedTime(data.end_at)}</Value></Topic>
					<OutlinedButton onClick={() => {
						return NavigateTo(`/torneio/${data.id}`);
					}}>PARTICIPAR</OutlinedButton>
				</DataDiv>
				<PrizeDiv>
					<Topic>PRÊMIOS</Topic>
					<>{returnPrize()}</>
				</PrizeDiv>
			</TournamentEntranceData>
		</TournamentEntranceDataDiv>
	</TournamentEntranceContainer>;
};

export default TournamentEntrance;