import { useRef } from "react";
import colors from "../constants/colors";
import { FeedbackContainer, FeedbackText } from "./styles";

const Feedback = (props: { success?: boolean, id: string }) => {
	const feedback = useRef<HTMLDivElement>(null);

	return <FeedbackContainer style={{ display: "block" }} ref={feedback}>
		<FeedbackText color={props.success ? colors.PRIMARY_GREEN : colors.PRIMARY_RED} id={props.id}>
		</FeedbackText>
	</FeedbackContainer>;
};

export default Feedback;