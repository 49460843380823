import { useState, useEffect } from "react";
import Template from "../../components/Template";
import Carousel from "../../components/UI/Carousel/Carousel";
import { CreateChallengeButton, FutureGameSpan, GameDiv, GameLogo, MenuBackground, MenuTitle } from "./styles";
import R6Background from "../../assets/Images/RainbowSixSiegeBackground.webp";
import LolBackground from "../../assets/Images/LeagueOfLegendsBackground.webp";
import LolLogo from "../../assets/Images/LeagueOfLegendsLogo.webp";
import RGLogo from "../../assets/Images/RainbowSixSiegeLogo.webp";
import TftBackground from "../../assets/Images/TFTBackground.webp";
import TftLogo from "../../assets/Images/TFTLogo2.webp";
import WildRiftLogo from "../../assets/Images/WildRiftLogo.webp";
import WildRiftBackground from "../../assets/Images/WildRiftBackground2.webp";
import WarzoneBackground from "../../assets/Images/WarzoneBackground.webp";
import GenericGameBackground from "../../assets/Images/generic-game-banner.webp";
import WarzoneLogo from "../../assets/Images/WarzoneLogo2.webp";
// import TournamentEntrance from "../../components/UI/TournamentEntrance/TournamentEntrance";
import { useNavigate } from "react-router-dom";
import { Challenges } from "../../types/Challenges";
import axiosInstance from "../../services/axiosInstance";
import Challenge from "../../components/UI/Challange/Challenge";
// import { Tournaments } from "../../types/Tournaments";
import AllGamesBackground from "../../assets/Images/riotAllGamesBackground.webp";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import colors from "../../components/UI/constants/colors";
import Modal from "../../components/UI/Modal/Modal";
import { OpenModal } from "../../functions/modalFunctions";
import CreateChallenge from "./createChallenge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import seo from "../../helpers/seo";
import { SwiperSlide } from "swiper/react";


const Home = () => {
	const [challengesAll, setChallenges] = useState<Challenges[]>();
	const [challengesLol, setChallengesLol] = useState<Challenges[]>();
	const [challengesWildRift, setChallengesWildRift] = useState<Challenges[]>();

	// const [tounaments, setTounaments] = React.useState<Tournaments[]>();
	const navigate = useNavigate();

	function NavigateTo(game: string) {
		window.hasPreviousUrl = true;
		navigate(`/jogo/${game}`);
	}

	useEffect(() => {
		seo({
			title: "Jogar"
		});
		axiosInstance.get("api/matches/solo?status[]=searching").then((res) => {
			setChallenges(res.data.data);
		});
	}, []);

	useEffect(() => {
		axiosInstance.get("api/matches/solo?game=lol&status[]=searching").then((res) => {
			setChallengesLol(res.data.data);
		});
	}, []);

	useEffect(() => {
		axiosInstance.get("api/matches/solo?game=wildrift&status[]=searching").then((res) => {
			setChallengesWildRift(res.data.data);
		});
	}, []);

	function returnRecent() {
		if (challengesAll) {
			return challengesAll.filter((challenge) => {
				const date = new Date();
				const start = new Date(challenge.created_at);
				const diffInMilliSeconds = Math.abs(date.getTime() - start.getTime());
				if (diffInMilliSeconds < 604800000) {
					return challenge;
				}
			});
		} else {
			return [];
		}
	}

	useEffect(() => {
		returnRecent();
	}, [challengesAll]);

	// useEffect(() => {
	// 	axiosInstance.get("api/tournament").then((res) => { setTounaments(res.data); }).catch((err) => { console.log(err); });
	// }, []);

	return <Template>
		<MenuBackground backgroundImage={AllGamesBackground} />
		<MenuTitle>SELECIONE UM JOGO</MenuTitle>
		<Carousel height={400}>
			<SwiperSlide style={{ height: 400, width: 350 }}>
				<GameDiv onClick={() => { NavigateTo("lol"); }} backgroundImage={LolBackground}>
					<GameLogo src={LolLogo} width="80%" alt="League of Legends logo" />
				</GameDiv>
			</SwiperSlide>
			<SwiperSlide style={{ height: 400, width: 350 }}>
				<GameDiv onClick={() => { NavigateTo("wildrift"); }} backgroundImage={WildRiftBackground}>
					<GameLogo src={WildRiftLogo} width="60%" alt="Wild Rift logo" />
				</GameDiv>
			</SwiperSlide>
			<SwiperSlide style={{ height: 400, width: 350 }}>
				<GameDiv onClick={() => { NavigateTo("generic"); }} backgroundImage={GenericGameBackground}>
					<span style={{ color: colors.WHITE, fontSize: 42, textAlign: "center", fontWeight: "bold" }}>ESCOLHA<br />SEU<br />JOGO</span>
				</GameDiv>
			</SwiperSlide>
			<SwiperSlide style={{ height: 400, width: 350 }}>
				<GameDiv backgroundImage={WarzoneBackground}>
					<GameLogo src={WarzoneLogo} width="80%" alt="Warzone logo"/>
					<FutureGameSpan>EM BREVE</FutureGameSpan>
				</GameDiv>
			</SwiperSlide>
			<SwiperSlide style={{ height: 400, width: 350 }}>
				<GameDiv backgroundImage={R6Background}>
					<GameLogo src={RGLogo} alt="R6 logo" />
					<FutureGameSpan>EM BREVE</FutureGameSpan>
				</GameDiv>
			</SwiperSlide>
			<SwiperSlide style={{ height: 400, width: 350 }}>
				<GameDiv backgroundImage={TftBackground}>
					<GameLogo src={TftLogo} width="80%" alt="TFT logo" />
					<FutureGameSpan>EM BREVE</FutureGameSpan>
				</GameDiv>
			</SwiperSlide>
		</Carousel>
		{/* <MenuTitle>TORNEIOS ABERTOS</MenuTitle>
		<Carousel height={343}>
			<>{tournaments && tournaments.map((tournament) => {
				return <CarouselItem key={tournament.id}>
					<TournamentEntrance data={tournament} />
				</CarouselItem>;
			})}</>
		</Carousel> */}
		<MenuTitle>DESAFIOS RECENTES DA SEMANA</MenuTitle>
		<Modal id="createChallenge">
			<CreateChallenge />
		</Modal>
		<Carousel height={350} >
			<SwiperSlide style={{ height: 295, width: 450 }}>
				<CreateChallengeButton onClick={() => OpenModal("createChallenge")}>
					<FontAwesomeIcon icon={faPlus} size="5x" color={colors.PRIMARY_GREEN} />
					<span>CRIE SEU PRÓPRIO DESAFIO</span>
				</CreateChallengeButton>
			</SwiperSlide>
			<>{challengesAll && returnRecent().map((challenge) => {
				return <SwiperSlide style={{ height: 295, width: 450 }} key={challenge.id}>
					<Challenge data={challenge} />
				</SwiperSlide>;
			})}</>
		</Carousel>
		<MenuTitle>DESAFIOS DE LOL</MenuTitle>
		<Carousel height={350} >
			<>{challengesLol && challengesLol.map((challenge) => {
				return <SwiperSlide style={{ height: 295, width: 450 }} key={challenge.id}>
					<Challenge data={challenge} />
				</SwiperSlide>;
			})}</>
		</Carousel>
		<MenuTitle>DESAFIOS DE WILD RIFT</MenuTitle>
		<Carousel height={350} >
			<>{challengesWildRift && challengesWildRift.map((challenge) => {
				return <SwiperSlide style={{ height: 295, width: 450 }} key={challenge.id}>
					<Challenge data={challenge} />
				</SwiperSlide>;
			})}</>
		</Carousel>
	</Template>;
};

export default Home;